import React, { useState, useEffect } from 'react';
import ExhibitionList from './exhibitionItem';
import { useAuth } from '../context/AuthContext';
import Exhibitionsingle from './exhibitionSingle';

const ExhibitionFetch = (props) => {
  const [activeTab, setActiveTab] = useState('HostExhibition');
  const [showDetail, setShowDetail] = useState(false);
  const [details, setDetails] = useState(null);

  

  const [data, setData] = useState([]);
  const { fetchExhibition, defUrllink } = useAuth();
  const imagesUrl = defUrllink + 'exhibuploads/';

  const [pagination, setPagination] = useState({
    offset: 0,
    limit: props.limit,
    page: 1,
  });
  const UserData = {
    userid: '',
    category: '',
    offset: pagination.offset,
    limit: pagination.limit
  };

  const prevPage = () => {
    setPagination(prev => ({
      ...prev,
      offset: Math.max(0, prev.offset - prev.limit),
      page: prev.page - 1
    }));
    fetchData();
  }

  const nextPage = () => {
    setPagination(prev => ({
      ...prev,
      offset: prev.offset + prev.limit,
      page: prev.page + 1
    }));
    fetchData();
  }

  const fetchData = async () => {
    try {
      const response = await fetchExhibition(UserData.userid, UserData.offset, props.limit);
      setData(response || []);  // Ensures data is always an array
    } catch (error) {
      console.error('Failed to fetch :', error);
      setData([]);  // Ensure data is reset to an empty array on error
    }
  }

  const backButton = () => {
    setShowDetail(false);
    setDetails(null);
    console.log('back button clicked');
  };

  const changeDetail = (item) => {
    setShowDetail(true);
    setDetails(item);
  }

  useEffect(() => {
    fetchData();
  }, [pagination]);

  return (
    <>
      {!showDetail ? (
        <div className="exhibition-fetch">
          {data.map((item, index) => (
            <div key={index} className="list-item-container flex items-center justify-between bg-light-100 p-2 rounded-lg cursor-pointer transition duration-200 md:gap-10 lg:gap-16 md:rounded-xl hover:bg-light-200">
              <img src={imagesUrl + item.dp_image} alt={item.name} className="list-item-image relative flex-none aspect-square w-14 rounded-md md:w-16 md:rounded-lg" />
              <div className="list-item-details">
                <h2 className="list-item-title text-sm font-bold line-clamp-1 md:text-base">{item.name}</h2>
                <p className="list-item-author flex items-center gap-1 text-xs md:text-sm font-semibold text-mid-300">{item.owner_name}</p>
                <p className="list-item-description text-xs line-clamp-1 md:text-sm">{item.description}</p>
              </div>
              <div className="list-item-price-container">
                {item.joinfee && <p className="list-item-price font-bold text-sm md:text-base">Floor <br />{item.joinfee} ETH</p>}
                <button 
                  className="list-item-join-button inline-flex items-center justify-center rounded-lg ring-offset-white transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-neutral-950 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-25 dark:ring-offset-neutral-950 dark:focus-visible:ring-neutral-300 text-neutral-50 dark:bg-neutral-50 dark:text-neutral-900 dark:hover:bg-neutral-50/90 py-2 h-9 px-3 text-sm bg-brand font-bold hover:bg-blue-600 md:h-11 md:px-4 md:text-base"
                  onClick={() => changeDetail(item)}
                >
                  Join
                </button>
              </div>
            </div>
          ))}
          {props.page === 'true' && (
            <nav aria-label="Page navigation example">
              <ul className="pagination justify-content-center">
                <li className="page-item">
                  <button disabled={pagination.page === 1} className="page-link" onClick={prevPage}>Previous</button>
                </li>
                <li className="page-item"><a className="page-link">{pagination.page}</a></li>
                <li className="page-item">
                  <button disabled={data.length < pagination.limit} className="page-link" onClick={nextPage}>Next</button>
                </li>
              </ul>
            </nav>
          )}
        </div>
      ) : (
        <Exhibitionsingle exData={details} onBackbutton={backButton} />
      )}
    </>
  );
};

export default ExhibitionFetch;
