import React, { useState, useEffect } from 'react';
import { useAuth } from '../context/AuthContext';
import { useParams } from "react-router-dom";

const Sendotp =( props) => {
  const [disabled, setDisabled] = useState(false);
  const [countdown, setCountdown] = useState(300); // 180 seconds = 3 minutes
  const  user  = props.email;
  const auth = useAuth();

  let intervalId; // Declare intervalId here

  useEffect(() => {
    // Retrieve countdown value from localStorage
    const storedCountdown = localStorage.getItem('countdown2');
    if (storedCountdown) {
      const parsedCountdown = JSON.parse(storedCountdown);
      const currentTime = new Date().getTime();
      const storedTime = new Date(parsedCountdown.timestamp).getTime();
      const timeDifference = currentTime - storedTime;
      const remainingTime = Math.max(0, parsedCountdown.value - Math.floor(timeDifference / 1000));
      setCountdown(remainingTime);
      setDisabled(true); // Disable button if countdown is still ongoing
      // Start countdown from the stored value
      startCountdown(remainingTime);
    }
  }, []);

  const startCountdown = (initialCountdown) => {
    intervalId = setInterval(() => {
      setCountdown(prevCountdown => {
        if (prevCountdown === 1) {
          clearInterval(intervalId);
          setDisabled(false); // Re-enable the button after countdown finishes
        }
        return prevCountdown - 1;
      });
    }, 1000);
  };

  const handleResendClick = async () => {
    try {
      await auth.sendotp(user); // Use the login function from context
    } catch (error) {
      // Handle error
    }
    setDisabled(true);
    startCountdown(300); // Start countdown from initial value
    // Store countdown value in localStorage
    const timestamp = new Date();
    localStorage.setItem('countdown2', JSON.stringify({ value: 300, timestamp }));
  };

  useEffect(() => {
    return () => {
      // Clear interval and remove countdown value from localStorage on component unmount
      clearInterval(intervalId);
      localStorage.removeItem('countdown2');
    };
  }, []); // Empty dependency array to run once on component mount

  return (
    <button className="text-primary" onClick={handleResendClick} disabled={disabled}>
      <span class="badge bg-primary">  {disabled ? `${countdown}s` : 'Send OTP'}</span>
    </button>
  );
};

export default Sendotp;
