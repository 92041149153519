import React, { useState, useEffect, useCallback } from 'react';
import 'swiper/css';
import 'swiper/css/navigation';
import './index.css';
import CollectionsSection from './landing/components/CollectionsSection';
import HeroSection from './landing/components/HeroSection';
import StatsTable from './landing/components/StatsTable';
import BaseLayout from './landing/components/layouts/BaseLayout';
import { useAuth } from './dashboard/context/AuthContext';

function App2() {
  const { allNfts } = useAuth();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (allNfts && allNfts.length > 0) {
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
  }, [allNfts]);

  return (
    <BaseLayout>
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <>
          <HeroSection allNfts={allNfts} />
          <CollectionsSection title="Art" allNfts={allNfts} />
          <CollectionsSection title="Gaming" allNfts={allNfts} />
          <CollectionsSection title="Membership" allNfts={allNfts} />
          <CollectionsSection title="PFPs" allNfts={allNfts} />
          <CollectionsSection title="Photography" allNfts={allNfts} />
          <CollectionsSection title="Others" allNfts={allNfts} />
          {/* Uncomment the following line if StatsTable component needs allNfts data */}
          {/* <StatsTable allNfts={allNfts} /> */}
        </>
      )}
    </BaseLayout>
  );
}

export default App2;
