import React, { useEffect, useState } from 'react';
import { useAuth } from '../context/AuthContext';
import ShowNft from '../components/shownft';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import Copy from '../api/copy';

const Mycollection = () => {
    const { userData, fetchUserData } = useAuth();
    const [data, setData] = useState(null);
    const navigate = useNavigate();

    const goBack = () => {
        navigate(-1); // Navigate back one step
    };

    async function fetchData() {
        try {
            const resp = await fetchUserData();
            if (resp !== null) {
                const UserData = {
                    userid: resp.map(user => user.uniqueid),
                    category: "",
                    offset: 0,
                    limit: 10,
                    page: true,
                    mine: true
                };
                setData(UserData);
            }
        } catch (error) {
            console.error("Error fetching user data:", error);
        }
    }

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <div className="main_content_iner">
            <div className="container-fluid plr_30 body_white_bg pt_10">
                <div className="row justify-content-center">
                    <div className="d-flex justify-content-between m-2">
                        <div className="d-flex align-items-center gap-3 fs-2 fw-bold">
                            <div className="d-flex mb-1" onClick={goBack}>
                                <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" className="cursor-pointer" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                    <path d="m15 18-6-6 6-6"></path>
                                </svg>
                            </div>
                            <h2 className="text-charcoal fw-bold">My Collections</h2>
                        </div>
                        <button className="btn btn-primary" type="button">
                            <Copy text={`https://Nftvortex.art/profile/${userData ? userData.map(user => (user.uniqueid)) : 'null'}`} />
                        </button>
                    </div>
                    <div className="max-w-3xl py-3 md:py-4">
                        <p>
                            {userData && userData.length > 0 ? (
                                // Render user bio if it exists, otherwise render link to update bio
                                userData.map(user => (
                                    user.bio !== null && user.bio !== undefined ? (
                                        <div key={user.uniqueid}>
                                            {user.bio}
                                        </div>
                                    ) : (
                                        <Link key={user.uniqueid} to='/dashboard/settings'>
                                            Click to update your Bio.....
                                        </Link>
                                    )
                                ))
                            ) : (
                                // Render link to update bio
                                <Link to='/dashboard/settings'>Click to update your Bio.....</Link>
                            )}
                        </p>
                    </div>
                    {/* code for displaying collections */}
                    <div>
                        {/* mock data */}
                        {/* map over collection data */}
                        <div className="single_element mt-3">
                            <div className="quick_activity">
                                <div className="row m mt-3">
                                    {data ? <ShowNft userData={data} /> : <>loading...</>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Mycollection;
