import React, { useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useAuth } from '../context/AuthContext';
import { toast, ToastContainer } from 'react-toastify';
import axiosInstance from '../api/axiosInstance'; // Import the Axios instance

const ExhibitionForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    joinFee: '',
    backgroundImage: '',
    displayImage: '',
  });
  const [backgroundImage, setBackgroundImage] = useState(null);
  const [displayImage, setDisplayImage] = useState(null);
  const [selectedNFTs, setSelectedNFTs] = useState([]);
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const [data, setData] = useState([]);
  const { fetchNftData,fetchUserData,defUrllink  } = useAuth();

  const fetchData = async () => {
    try {
      const resp = await fetchUserData()
        const UserData = {
            userid: resp.map(user => user.uniqueid),
            category: "",
            offset: 0,
            limit: 1000,
            mine:true,    
        }; 
        if(UserData.userid !== undefined){
            const response = await fetchNftData(UserData.userid, UserData.category, UserData.offset, UserData.limit);
            setData(response); // Store user data in state
            console.log(response);
        }
        // const response = await fetchNftData(props.userData.userid, props.userData.category, pagination.offset, pagination.limit);
        // setData(response); // Store user data in state
    } catch (error) {
        console.error('Fetching data failed:', error);
        // Handle error
    }
};

useEffect(() => {
    fetchData();
}, []);

  const nftImages = [
    {
      url: 'https://via.placeholder.com/150',
      title: 'Imagination Unleashed',
      floor: 'Floor',
      weth: '3.2 WETH',
    },
    {
      url: 'https://via.placeholder.com/150',
      title: 'Flaming Sky',
      floor: 'Floor',
      weth: '1.5 WETH',
    },
    {
      url: 'https://via.placeholder.com/150',
      title: 'Mystic Forest',
      floor: 'Floor',
      weth: '2.0 WETH',
    },
    {
      url: 'https://via.placeholder.com/150',
      title: 'Cosmic Dreams',
      floor: 'Floor',
      weth: '4.2 WETH',
    },
    {
      url: 'https://via.placeholder.com/150',
      title: 'Ocean Depths',
      floor: 'Floor',
      weth: '3.0 WETH',
    },
    {
      url: 'https://via.placeholder.com/150',
      title: 'Ancient Ruins',
      floor: 'Floor',
      weth: '2.5 WETH',
    },
    {
      url: 'https://via.placeholder.com/150',
      title: 'Galactic Voyage',
      floor: 'Floor',
      weth: '5.0 WETH',
    },
    {
      url: 'https://via.placeholder.com/150',
      title: 'Desert Mirage',
      floor: 'Floor',
      weth: '1.8 WETH',
    },
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleImageChange = (e) => {
    const { name, value, files } = e.target;
    if (name === 'backgroundImage') {
      setBackgroundImage(URL.createObjectURL(files[0]));
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: files[0]
    }));
      
    } else if (name === 'displayImage') {
      setDisplayImage(URL.createObjectURL(files[0]));
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: files[0]
    }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(formData, selectedNFTs);
    if (selectedNFTs.length < 5) {
      toast.warning('Please select at least 5 NFTs', {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      const formDataToSend = new FormData();
      formDataToSend.append('name', formData.name);
      formDataToSend.append('description', formData.description);
      formDataToSend.append('joinFee', formData.joinFee);
      formDataToSend.append('backgroundImage', formData.backgroundImage);
      formDataToSend.append('displayImage', formData.displayImage);
      formDataToSend.append('selectedNFTs', JSON.stringify(selectedNFTs));

      try {
        const response = await axiosInstance.post('main.php?xAction=addExhibition&', formDataToSend, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        if (response.data.status === 'success') {
          toast.success(response.data.id, {
            position: "top-center"
          });

          // If you're using a <form> element, reset it directly to also clear file input
          document.getElementById("uploadForm").reset();
          return true;
        } else {
          toast.error(response.data.id, {
            position: "top-center"
          });
          return false;
        }
      } catch (error) {
        console.error('Exhibition failed:', error);
        return false;
      }
    }
  };

  const handleNFTClick = (nft) => {
    setSelectedNFTs((prevSelected) => {
      if (prevSelected.includes(nft.link)) {
        return prevSelected.filter((link) => link !== nft.link);
      } else {
        return [...prevSelected, nft.link];
      }
    });
    setFormData({ ...formData, 'selectedNFTs': selectedNFTs });
  };

  const styles = {
    form: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      maxWidth: isMobile ? '300px' : '660px',
      margin: '0 auto',
      padding: '5px',
      borderRadius: '10px',
    },
    imageUpload: {
      position: 'relative',
      width: '100%',
      height: '200px',
      marginBottom: '20px',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '10px',
      border: '1px dashed #ccc',
    },
    fileInput: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      opacity: 0,
      cursor: 'pointer',
    },
    displayImageContainer: {
      position: 'absolute',
      bottom: '10px',
      left: '10px',
      width: '100px',
      height: '100px',
      backgroundColor: '#fff',
      borderRadius: '10px',
      overflow: 'hidden',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      border: '1px dashed #ccc',
    },
    displayImage: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
    imagePlaceholder: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: '24px',
      color: '#ccc',
    },
    formGroup: {
      width: '100%',
      marginBottom: '15px',
    },
    input: {
      width: '100%',
      padding: '10px',
      fontSize: '16px',
      borderRadius: '5px',
      border: '1px solid #ccc',
    },
    textarea: {
      width: '100%',
      padding: '10px',
      fontSize: '16px',
      borderRadius: '5px',
      border: '1px solid #ccc',
      minHeight: '100px',
    },
    button: {
      padding: '10px 20px',
      fontSize: '16px',
      color: '#fff',
      backgroundColor: '#007bff',
      border: 'none',
      borderRadius: '5px',
      cursor: 'pointer',
      
    },
    Submitbutton: {
      width: '100%',
      padding: '10px 20px',
      fontSize: '16px',
      color: '#fff',
      backgroundColor: '#007bff',
      border: 'none',
      borderRadius: '5px',
      cursor: 'pointer',
      
    },
    nftSection: {
      width: '100%',
      marginBottom: '20px',
      padding: '20px',
      borderRadius: '15px',
      backgroundColor: '#f9f9f9',
    },
    nftHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: '10px',
    },
    nftTitle: {
      fontSize: '18px',
      fontWeight: 'bold',
    },
    nftCount: {
      fontSize: '18px',
      fontWeight: 'bold',
      color: '#ff0000',
    },
    nftScroll: {
      display: 'flex',
      overflowX: 'scroll',
      paddingBottom: '10px',
    },
    nftCard: {
      position: 'relative',
      minWidth: '150px',
      maxWidth: '150px',
      height: '250px',
      margin: '0 10px',
      backgroundColor: '#f9f9f9',
      borderRadius: '10px',
      overflow: 'hidden',
      cursor: 'pointer',
      border: '2px solid transparent',
      transition: 'border-color 0.3s',
    },
    nftImage: {
      width: '100%',
      height: '100px',
      objectFit: 'cover',
    },
    nftInfo: {
      padding: '10px',
      backgroundColor: '#fff',
      textAlign: 'center',
    },
    nftTitleText: {
      fontSize: '14px',
      fontWeight: 'bold',
      margin: '5px 0',
      maxWidth: '100%', // Ensure the title doesn't exceed the card width
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    nftFloor: {
      fontSize: '12px',
      color: '#666',
    },
    nftWeth: {
      fontSize: '12px',
      color: '#666',
    },
    addButton: {
      marginTop: '5px',
      padding: '5px 10px',
      fontSize: '12px',
      color: '#fff',
      backgroundColor: '#007bff',
      border: 'none',
      borderRadius: '5px',
      cursor: 'pointer',
     
    },
  };

  return (
    <form onSubmit={handleSubmit} style={styles.form}>
      <div style={{ ...styles.imageUpload, backgroundImage: `url(${backgroundImage})` }}>
        <input
          type="file"
          name="backgroundImage"
          onChange={handleImageChange}
          style={styles.fileInput}
          required
          accept="image/*"
        />
        <div style={styles.displayImageContainer}>
          <input
            type="file"
            name="displayImage"
            onChange={handleImageChange}
            style={styles.fileInput}
            required
            accept="image/*"
          />
          {displayImage ? (
            <img src={displayImage} alt="Display" style={styles.displayImage} />
          ) : (
            <div style={styles.imagePlaceholder}>
              <span>+</span>
            </div>
          )}
        </div>
      </div>
      <div style={styles.formGroup}>
        <label htmlFor="name">Name</label>
        <input
          type="text"
          id="name"
          name="name"
          value={formData.name}
          onChange={handleChange}
          placeholder="Give your exhibition a name."
          style={styles.input}
          required
        />
      </div>
      <div style={styles.formGroup}>
        <label htmlFor="description">Description</label>
        <textarea
          id="description"
          name="description"
          value={formData.description}
          onChange={handleChange}
          placeholder="Provide a detailed description of your exhibition."
          style={styles.textarea}
          required
        />
      </div>
      <div style={styles.formGroup}>
        <label htmlFor="joinFee">Join Fee In ETH</label>
        <input
          type="number"
          id="joinFee"
          name="joinFee"
          value={formData.joinFee}
          onChange={handleChange}
          placeholder="Enter the join fee in ETH"
          style={styles.input}
          required
        />
      </div>
      <div style={styles.nftSection}>
        <div style={styles.nftHeader}>
          <h2 style={styles.nftTitle}>Select at least 5 NFTs</h2>
          <span style={styles.nftCount}>{selectedNFTs.length}</span>
        </div>
        <div style={styles.nftScroll}>
          {data.map((nft, index) => (
            <div
              key={index}
              style={{
                ...styles.nftCard,
                border: selectedNFTs.includes(nft.link) ? '2px solid #007bff' : 'none',
              }}
              onClick={() => handleNFTClick(nft)}
            >
              <img src={defUrllink + 'nftuploads/' +nft.link} alt={nft.title} style={styles.nftImage} />
              <div style={styles.nftInfo}>
                <h3 style={styles.nftTitleText}>{nft.title}</h3>
                <p style={styles.nftFloor}>Floor</p>
                <p style={styles.nftWeth}>{nft.price} WETH</p>
                <span style={styles.addButton}>
                  {selectedNFTs.includes(nft.link) ? 'Remove' : 'Add'}
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
      <button type="submit" style={styles.Submitbutton} className='btn btn-lg'>Host Exhibition</button>
    </form>
  );
};

export default ExhibitionForm;
