import React, { useState } from 'react';
import { useAuth } from '../context/AuthContext';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'bootstrap-icons/font/bootstrap-icons.css';
import Header from '../../landing/components/Header';
import Sendotp from './sendotp';

const Resetpassword = () => {

    const [formData, setFormData] = useState({
        otp: '',
        email: '',
        password: '',
        confirmPassword: '',
       
    });

    const [error, setError] = useState(null); // State to store registration error
    const { resetpassword } = useAuth();
    const [showPassword, setshowPassword] = useState(false);
    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: type === 'checkbox' ? checked : value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validate form fields
       
        if (formData.password !== formData.confirmPassword) {
            //alert('Passwords do not match.');
            toast.warning('Passwords do not match.', {
                position: "top-right"
            });
            return;
        }
        // Send registration request
        // Replace with your registration API endpoint and logic

        try {
            await resetpassword(formData.otp, formData.email, formData.password);
            // Redirect user to dashboard or any other page upon successful registration
        } catch (error) {
            console.error('Registration failed:', error);
            setError('Registration failed. Please try again.');
        }
        console.log('Form submitted:', formData);
    };

    return (
        <>
        <Header/>
        <div className="main_content_iner ">
            <div class="container-fluid plr_30 body_white_bg pt_30">
                <div class="row justify-content-center">
                    <div class="col-lg-12">
                        <div class="white_box mb_30">
                            <div class="row justify-content-center">
                                <div class="col-lg-6">

                                    <div class="modal-content cs_modal">
                                        <div class="modal-header">
                                            <h5 class="modal-title">Reset Password</h5>
                                        </div>
                                        <div class="modal-body">
                                            <form onSubmit={handleSubmit}>

                                                <div class="">
                                                    <input type="email"
                                                        name="email"
                                                        value={formData.email}
                                                        onChange={handleChange}
                                                        required class="form-control" placeholder="Enter your email" />
                                                </div>
                                                <div className="input-group">
                                                        <input
                                                            type="number"
                                                            className="form-control"
                                                            placeholder="Enter OTP"
                                                            name='otp'
                                                            maxLength='6'
                                                            value={formData.otp}
                                                            onChange={handleChange}
                                                            required
                                                        />
                                                        <div className="input-group-append mt-2" style={{ position: 'absolute', right: '0rem', zIndex: '100' }}>
                                                        <button type="button" className="btn" >
                                                            <Sendotp email={formData.email} />
                                                        </button>
                                                    </div>
                                                    </div>
                                               

                                                <div className="input-group">
                                                    <input
                                                        type={showPassword ? 'text' : 'password'}
                                                        className="form-control "
                                                        name='password'
                                                        placeholder="Password"
                                                        value={formData.password}
                                                        onChange={handleChange}
                                                        required
                                                        style={{ height: '50px' }} // Increase input height
                                                    />
                                                    <div className="input-group-append mt-2" style={{ position: 'absolute', right: '0rem', zIndex: '100' }}>
                                                        <button
                                                            type="button"
                                                            className="btn"
                                                            onClick={() => setshowPassword(!showPassword)}
                                                        >
                                                            <i className={showPassword ? 'bi bi-eye-slash' : 'bi bi-eye'}></i>
                                                        </button>
                                                    </div>
                                                </div>
                                                <div class="">
                                                    <input 
                                                    type={showPassword ? 'text' : 'password'}
                                                        name="confirmPassword"
                                                        value={formData.confirmPassword}
                                                        onChange={handleChange}
                                                        required class="form-control" placeholder="Confirm Password" />
                                                </div>
                                                
                                                <button href="#" class="btn_1 full_width text-center" type='submit'> Reset</button>


                                                <p>Remember password? <Link to="/login">Log in</Link></p>
                                                

                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    );

};

export default Resetpassword;