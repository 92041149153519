export const MAIN_TABS = ['All', 'Art', 'Gaming', 'Memberships', 'PFPs', 'Photography','Others'];

export const CAROUSEL_ITEMS = [
  { image: '8.png', name: 'Collection Name', floor: '0.03ETH' },
  { image: '1.png', name: 'Collection Name', floor: '0.03ETH' },
  { image: '2.png', name: 'Collection Name', floor: '0.03ETH' },
  { image: '3.png', name: 'Collection Name', floor: '0.03ETH' },
  { image: '5.png', name: 'Collection Name', floor: '0.03ETH' },
  { image: '6.png', name: 'Collection Name', floor: '0.03ETH' },
  { image: '7.png', name: 'Collection Name', floor: '0.03ETH' },
  { image: '9.png', name: 'Collection Name', floor: '0.03ETH' },
  { image: '4.png', name: 'Collection Name', floor: '0.03ETH' },
  { image: '10.jpg', name: 'Collection Name', floor: '0.03ETH' },
  { image: '11.png', name: 'Collection Name', floor: '0.03ETH' },
  { image: '12.jpg', name: 'Collection Name', floor: '0.03ETH' },
];
export const PAGE_ITEMS = [
  { name: 'Art', 
    item: '2116', 
    chain: 'Ethereum',
    description: 'Immerse yourself in the world of digital art with our NFT platforms dedicated art category. Discover and collect one-of-a-kind digital artworks created by talented artists from around the globe. From stunning paintings and sculptures to mesmerizing animations and immersive installations, experience the limitless possibilities of digital creativity.',
    total_volume: '6274 ETH',
    listed: '76%',
    owners: '634',
    unique_owners: '19%',
    bg_link: 'art-banner.jpg',
    img: 'art-pfp.webp',
   },
   { name: 'Gaming', 
   item: '460', 
   chain: 'Ethereum',
   description: 'Immerse yourself in the exciting world of NFT gaming with our dedicated gaming category. Collect rare in-game items, virtual assets, and unique characters that can be used across various gaming platforms and decentralized applications. Experience the thrill of owning and trading valuable digital items that enhance your gaming experience, while also having the potential to appreciate in value over time.',
   total_volume: '1740 ETH',
   listed: '69%',
   owners: '138',
   unique_owners: '12%',
   bg_link: 'gaming-banner.jpg',
   img: 'gaming-pfp.mp4',
  },
  { name: 'Memberships', 
   item: '299', 
   chain: 'Ethereum',
   description: 'Unlock premium features, exclusive content, and exciting perks by becoming a member of our NFT platform. Collect membership NFTs that grant you access to special communities, events, and collaborations. Enjoy early access to new releases, discounts on purchases, and the ability to connect with like-minded collectors and creators in an exclusive environment.',
   total_volume: '1131 ETH',
   listed: '61%',
   owners: '90',
   unique_owners: '13%',
   bg_link: 'memberships-banner.jpg',
   img: 'memberships-pfp.webp',
  },
  { name: 'Pfps', 
   item: '230', 
   chain: 'Ethereum',
   description: 'Customize your online presence with exclusive and eye-catching Profile Picture (PFP) NFTs. Express your personality and individuality through a diverse selection of unique avatar designs, digital portraits, and animated characters. Stand out from the crowd and elevate your online identity with limited-edition PFPs on our platform.',
   total_volume: '870 ETH',
   listed: '73%',
   owners: '69',
   unique_owners: '14%',
   bg_link: 'pfps-banner.jpg',
   img: 'pfps-pfp.webp',
  },
  { name: 'Photography', 
   item: '322', 
   chain: 'Ethereum',
   description: 'Discover the power of visual storytelling through the captivating medium of digital photography. Browse our photography category to explore breathtaking landscapes, intimate portraits, striking still life compositions, and thought-provoking documentary series. Each photograph is meticulously captured and curated by talented photographers, ensuring a collection of truly unique and immersive visual experiences.',
   total_volume: '1218 ETH',
   listed: '89%',
   owners: '97',
   unique_owners: '10%',
   bg_link: 'photography-banner.jpg',
   img: 'photography-pfp.webp',
  },
  { name: 'Others', 
   item: '3205', 
   chain: 'Ethereum',
   description: 'Embark on a journey through uncharted digital landscapes with our latest category. Explore a diverse collection of unconventional creations, each pushing the boundaries of creativity. From avant-garde digital expressions to mind-bending conceptual pieces, this category is a playground for those who seek the extraordinary. Immerse yourself in a world where the unconventional reigns supreme, and where every creation tells a story that defies the ordinary.',
   total_volume: '7004 ETH',
   listed: '71%',
   owners: '816',
   unique_owners: '17%',
   bg_link: 'photography-banner.jpg',
   img: 'photography-pfp.webp',
  },
];

export const STATS_TABLE = [
  { image: '1.png', name: 'Collection Name', floor: 0.03, volume: 25 },
  { image: '2.png', name: 'Collection Name', floor: 0.03, volume: 25 },
  { image: '3.png', name: 'Collection Name', floor: 0.03, volume: 25 },
  { image: '4.png', name: 'Collection Name', floor: 0.03, volume: 25 },
  { image: '5.png', name: 'Collection Name', floor: 0.03, volume: 25 },
  { image: '6.png', name: 'Collection Name', floor: 0.03, volume: 25 },
  { image: '7.png', name: 'Collection Name', floor: 0.03, volume: 25 },
  { image: '8.png', name: 'Collection Name', floor: 0.03, volume: 25 },
  { image: '9.png', name: 'Collection Name', floor: 0.03, volume: 25 },
  { image: '10.jpg', name: 'Collection Name', floor: 0.03, volume: 25 },
];

export const FOOTER_MARKETPLACE = [
  { label: 'All NFTs', link: '/' },
  { label: 'Art', link: '/art' },
  { label: 'Gaming', link: '/gaming' },
  { label: 'Memberships', link: '/memberships' },
  { label: 'PFPs', link: '/pfps' },
  { label: 'Photography', link: '/photography' }
];

export const FOOTER_MYACCOUNT = [
  { label: 'Profile', link: '/dashboard/settings' },
  { label: 'My Collections', link: '/dashboard/mycollection' },
  { label: 'Create', link: '/dashboard/create-nft' },
  { label: 'Settings', link: '/dashboard/settings' }
];

export const FOOTER_STATS = ['Rankings', 'Activity'];
export const FOOTER_RESOURCES = [
  'Blog',
  'Learn',
  'Help Center',
  'User Content FAQs',
  'Taxes',
  'Partners',
  'Developer Platform',
  'Platform Status',
];
export const FOOTER_COMPANY = [
  { label: 'Privacy Policy', link: '/privacy-policy' },
  { label: 'Terms of Service', link: '/terms-of-service' },
];
export const FOOTER_LEARN = [
  'What is an NFT?',
  'How to buy an NFT',
  'What are NFT drops?',
  'How to sell an NFT using Snoo',
  'How to create an NFT on Snoo',
  'What is a crypto wallet?',
  'What is cryptocurrency?',
  'What are blockchain gas fees?',
  'What is a blockchain?',
  'What is web3?',
  'How to stay protected in web3',
];
