import React from "react";
import { Navigate, Outlet, useRoutes } from 'react-router-dom';
import '../css/bootstrap1.min.css';
import '../css/metisMenu.css';
import '../css/style1.css';
import '../css/colors/default.css';
import '../css/themefy_icon/themify-icons.css'
import CustomRoute from "../ScrollToTop";





const Dashboard = () => {

    return <><Outlet /> </>;
  
}
export default Dashboard;