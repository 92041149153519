import React, { useMemo, useState, useEffect } from 'react';
import BaseLayout from "../components/layouts/BaseLayout";
import { useParams } from 'react-router-dom';
import Container from "../components/Container";
import {CAROUSEL_ITEMS} from '../fetchConst'
import { fetchEthToUsdRate} from '../../dashboard/api/ethrate'
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../dashboard/context/AuthContext';
import { Link } from 'react-router-dom';
const Product = () => {
    const { productId } = useParams();
    const [isDataFetched, setIsDataFetched] = useState(false);
    const [shuffledData, setShuffledData] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);
    const [ethToUsdRate, setEthToUsdRate] = useState(null);
    const auth = useAuth();
    const navigate = useNavigate();
    const goBack = () => {
        navigate(-1); // Navigate back one step
    };


    const handleBuy = async (id , val) => {
        try {
            await auth.buyArt(id,val);
        } catch (error) {
            console.error(error);
            
        }
    };

    const fetchData = async () => {
        try {
            const response = await auth.nftdata(productId);
            setShuffledData(response);
            console.log(response);
        // Check if both arrays are not empty to indicate data fetching is complete
        if (selectedItems.length > 0) {
          setIsDataFetched(true);
        }
            //setData(response); // Store user data in state
        } catch (error) {
            console.error('Fetching data failed:', error);
            // Handle error
        }
    };

   
    useEffect(() => {
        setSelectedItems(CAROUSEL_ITEMS);
        
        const getEthToUsdRate = async () => {
            const rate = await fetchEthToUsdRate();
            setEthToUsdRate(rate);
          };
      
          getEthToUsdRate();
          fetchData();

    },[fetchData, selectedItems,ethToUsdRate, fetchEthToUsdRate]);
    
    return (
        <BaseLayout>
        {isDataFetched ? (
        <>
         {shuffledData.map((item, i) => (
            <Container>
                <div class="pt-4 md:pt-6">
                    <button onClick={goBack} class="justify-center rounded-lg font-medium ring-offset-white transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-neutral-950 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-25 dark:ring-offset-neutral-950 dark:focus-visible:ring-neutral-300 dark:bg-neutral-50 dark:text-neutral-900 dark:hover:bg-neutral-50/90 py-2 flex items-center gap-1 text-sm text-charcoal h-9 px-2 bg-light-100 hover:bg-light-200"><svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" height="20" width="20" xmlns="http://www.w3.org/2000/svg"><path d="m15 18-6-6 6-6"></path></svg><div class="mr-1">Back</div></button></div>
                <div class="flex flex-col gap-12 pt-4 pb-10 md:gap-16 md:pt-6 md:pb-20">
                    <div class="flex flex-col gap-6">
                        <section class="grid gap-4 md:gap-8 lg:gap-12 md:grid-cols-[3fr_4fr] lg:grid-cols-[9fr_16fr]"><div class="relative w-full aspect-square rounded-2xl">
                            <img alt="Image" loading="lazy" src={`https://nftvortex.art/snooart/nftuploads/${item.link}`} decoding="async" data-nimg="fill" class="object-cover object-center rounded-2xl" sizes="100vw"
                                style={{
                                    position: 'absolute',
                                    height: '100%',
                                    width: '100%',
                                    inset: 0,
                                    color: 'transparent'
                                }}

                                />
                        </div>
                            <div class="flex flex-col gap-6 md:gap-10">
                                <div class="flex flex-col gap-4 md:gap-6">
                                    <div class="flex flex-col gap-1">
                                        <h1 class="capitalize text-3xl md:text-4xl font-bold">{item.title}</h1>
                                        <div class="flex items-center gap-1">
                                            <h3>
                                                <span class="text-sm md:text-base">Owned by </span>
                                                <span class="font-bold md:text-lg"><Link to={'/profile/'+item.uniqueid}>{item.buyer_username !== '' ? item.buyer_username : item.owner_username}</Link></span>
                                            </h3>
                                            
                                        </div>
                                    </div>
                                    <div>
                                        <p class="text-justify">{item.description}</p>
                                    </div>
                                </div>
                                <div class="md:hidden lg:block">
                                    <section class="flex flex-col gap-4 p-6 bg-light-100 rounded-xl md:p-8 md:gap-8">
                                        <div class="flex flex-col gap-2">
                                            <p class="text-xs text-mid-300 md:text-sm">Current Price</p>
                                            <div>
                                                <h1 class="flex items-baseline gap-1 font-[900] text-3xl md:text-4xl"><span>{item.price}</span>
                                                    <div class="flex items-center gap-1">
                                                        <span class="text-base md:text-lg">WETH</span>
                                                        
                                                    </div>
                                                </h1>
                                                <p class="flex items-center gap-1"><span>≈</span><span class="text-sm md:text-base">{ethToUsdRate !== null ? (<p> {((ethToUsdRate*item.price)).toLocaleString(undefined, {style: 'currency',currency: 'USD',currencyDisplay: 'symbol'}).replace('US$', '$')}</p>) : (<div class="spinner-border text-secondary" role="status"><span class="visually-hidden">Loading...</span></div>)}</span></p>
                                            </div>
                                        </div>
                                        <div>
                                            <button onClick={() => handleBuy(item.link, item.price)} class="inline-flex items-center justify-center ring-offset-white transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-neutral-950 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-25 dark:ring-offset-neutral-950 dark:focus-visible:ring-neutral-300 text-neutral-50 dark:bg-neutral-50 dark:text-neutral-900 dark:hover:bg-neutral-50/90 px-6 py-2 gap-3 h-12 rounded-md w-full bg-brand font-bold hover:bg-blue-600 md:h-14">
                                                <span>Buy NFT</span>
                                            </button>
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </section>
                        
                    </div>
                </div>
            </Container>
            ))}
        </>
         ) : (
            <div class="spinner-border text-secondary" role="status"><span class="visually-hidden">Loading...</span></div>)
          }
        </BaseLayout>
    )
}
export default Product;