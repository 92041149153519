import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../../landing/components/Header';
import { toast } from 'react-toastify';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { useParams } from "react-router-dom";
import ResendOTPButton from './ResendOTPButton'; // Import the ResendOTPButton component
import { useAuth } from '../context/AuthContext';
import { NavLink } from 'react-router-dom';

const OTPPage = () => {
    const [otp, setOTP] = useState('');
    const navigate = useNavigate();
    const { user } = useParams();
    const auth = useAuth();

    // Redirect to login page if user param is not set
    useEffect(() => {
        if (!user) {
            navigate('/login');
        }
    }, [user, navigate]);

    const handleOTPSubmit = async (event) => {
        event.preventDefault();
        // Implement OTP verification logic here
        // For demonstration, let's assume OTP verification is successful
        try {
            await auth.otp(user, otp); // Use the login function from context
           
            // Redirect the user to the appropriate page after successful OTP verification
           // navigate('/dashboard');
           
          } catch (error) {
            console.error('OTP failed:', error);
           
           
              toast.error('OTP FAILED!', {
                position: "top-right"
              });
           
          }
        
    };

    return (
        <>
            <Header />
            <div className="main_content_iner">
                <div className="container-fluid plr_30 body_white_bg pt_30">
                    <div className="row justify-content-center">
                        <div className="col-lg-12">
                            <div className="white_box mb_30">
                                <div className="row justify-content-center">
                                    <div className="col-lg-6">
                                        <div className="modal-content cs_modal">
                                            <div className="modal-header">
                                                <h5 className="modal-title">Enter OTP</h5>
                                            </div>
                                            <div className="modal-body">
                                                <form onSubmit={handleOTPSubmit}>
                                                    <div className="border_style">
                                                        <span>Enter the OTP sent to your email</span>
                                                    </div>
                                                    <div className="">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Enter OTP"
                                                            value={otp}
                                                            onChange={(e) => setOTP(e.target.value)}
                                                        />
                                                    </div>
                                                    <button className="btn_1 full_width text-center" type="submit">Submit OTP</button>
                                                     {/* Use ResendOTPButton component */}
                                                </form>
                                                <div class="container mt-3">
                                                    <div class="row">
                                                        <div class="col">
                                                            <span>Didn't receive OTP? <ResendOTPButton /></span>
                                                        </div>
                                                        <div class="col text-right">
                                                            <span><NavLink onClick={auth.logout}>Logout</NavLink></span>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default OTPPage;
