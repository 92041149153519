import React, { useEffect, useState } from 'react';
import { useAuth } from '../context/AuthContext';
import NftSingle from '../components/nftSingle';
import { Link } from 'react-router-dom';
import AddExhibition from './addexhibition';
const ShowNft = ({userData, exhibitionData}) => {
    const [data, setData] = useState([]);
    const [pagination, setPagination] = useState({
        offset: 0, // Start from 0 to match typical array indices
        limit: userData.limit,
        page: 1,
    });
    const { fetchNftData } = useAuth();

    const prevPage = () => {
        setPagination(prev => ({
            ...prev,
            offset: Math.max(0, prev.offset - prev.limit),
            page: prev.page - 1
        }));
    };

    const nextPage = async () => {
        const nextPageOffset = pagination.offset + pagination.limit;
        try {
            const response = await fetchNftData(userData.userid, userData.category, nextPageOffset, pagination.limit);
            if (response.length > 0) {
                // If next page is not empty, increment the page number
                setPagination(prev => ({
                    ...prev,
                    offset: nextPageOffset,
                    page: prev.page + 1
                }));
                setData(response);
            } else {
                // If next page is empty, stay on the current page
                console.log("Next page is empty");
            }
        } catch (error) {
            console.error('Fetching data failed:', error);
            // Handle error
        }
    };

    const fetchData = async () => {
        try {
            const response = await fetchNftData(userData.userid, userData.category, pagination.offset, pagination.limit);
            setData(response); // Store user data in state
        } catch (error) {
            console.error('Fetching data failed:', error);
            // Handle error
        }
    };

    useEffect(() => {
        fetchData();
    }, [pagination]);

    return (
        <div>
            {data.length > 0 ? (
                <div className='row p-0 m-1'>
                    {data
                    
                    .map((nft) => (
                        <NftSingle
                            key={nft.id}
                            name={nft.title}
                            price={nft.price}
                            image={nft.link}
                            category={nft.category}
                            mine={userData.mine}
                            minted= {nft.minted}
                        />
                    ))}
                    {userData.category === 'allnft' ?
                    <div className='col-lg-4 col-6 p-1'>
                    <div class="flex items-center justify-center cursor-pointer bg-light-100 shadow-lg p-3- w-full  h-full rounded-xl" >
                       <AddExhibition exData={exhibitionData}/>
                        </div>
                    </div>
                :<></>}
                </div>
            ) : (
                
                <section>
                    <section class="flex flex-col justify-between gap-4">
                        <div class="relative bg-light-100 p-8 rounded-xl overflow-clip">
                            <div class="flex flex-col gap-8">
                                <div class="flex flex-col gap-1 z-30">
                                    <h3 class="text-xl md:text-4xl font-[900]">Create your own NFT</h3>
                                    <p class="text-brand text-xs md:max-w-none md:text-base">Buy and sell NFTs from top artists</p>
                                    </div>
                                    <div>
                                        <Link to="/dashboard/create-nft">
                                            <button class="justify-center font-medium ring-offset-white transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-neutral-950 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-25 dark:ring-offset-neutral-950 dark:focus-visible:ring-neutral-300 text-neutral-50 dark:bg-neutral-50 dark:text-neutral-900 dark:hover:bg-neutral-50/90 h-11 px-6 py-2 flex items-center gap-2 rounded-md w-40 bg-brand hover:bg-brand/80 md:w-64 lg:w-72">
                                                <span>Create</span>
                                                <span>
                                                    <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" height="20" width="20" xmlns="http://www.w3.org/2000/svg"><path d="M5 12h14"></path><path d="m12 5 7 7-7 7"></path></svg>
                                                </span>
                                                </button>
                                                </Link>
                                                </div>
                                                </div>
                                                <div class="absolute bottom-0 right-4 z-20">
                                                    <img alt="create nft" loading="lazy" width="201" height="234" decoding="async" data-nimg="1" class="w-[110px] md:w-[140px] lg:w-[170px]" style={{color: 'transparent'}}  
                                                    src="/ape.webp" />
                                                    </div>
                                                    <div class="absolute bottom-0 right-0 z-0">
                                                        <img alt="create nft" loading="lazy" width="236" height="212" decoding="async" data-nimg="1" class="w-[140px] md:w-[170px] lg:w-[210px]" style={{color: 'transparent'}} 
                                                        src="/ellipse.webp" />
                                                        </div>
                                                        </div>
                </section>
                </section>
            )}

            {data.length > 0 ? userData.page === true && (
                <nav aria-label="Page navigation example">
                    <ul className="pagination justify-content-center">
                        <li className="page-item">
                            <button disabled={pagination.page === 1} className="btn btn-secondary m-1" onClick={prevPage}>Previous</button>
                        </li>
                        <li className="page-item"><a className="btn btn-outline m-1" >{pagination.page}</a></li>
                        <li className="page-item">
                            <button disabled={data.length < pagination.limit} className="btn btn-secondary m-1" onClick={nextPage}>Next</button>
                        </li>
                    </ul>
                </nav>
            )
            :<></>
        }
        </div>
    );
};

export default ShowNft;
