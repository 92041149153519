import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext'; // Import useAuth hook
import {Link} from 'react-router-dom';
import Header from '../../landing/components/Header';
import { toast } from 'react-toastify';
import 'bootstrap-icons/font/bootstrap-icons.css';


const Login = () => {

    
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [showOldPassword, setShowOldPassword] = useState(false);
    const [loginError, setLoginError] = useState(null);
    const auth = useAuth(); // Use the custom hook to access context
    const history = useNavigate(); // Use the react-router-dom hook to navigate

    const handleLogin = async (event) => {
      event.preventDefault();
      try {
        await auth.login(username, password); // Use the login function from context
       
      } catch (error) {
        console.error('Login failed:', error);
        setLoginError('Login failed. Please try again.');
       
          toast.error('Failed to log in. Please check your credentials and try again.', {
            position: "top-right"
          });
       
      }
    };
    return (
        <>
        <Header />
        <div className="main_content_iner ">
            <div class="container-fluid plr_30 body_white_bg pt_30">
                <div class="row justify-content-center">
                    <div class="col-lg-12">
                        <div class="white_box mb_30">
                            <div class="row justify-content-center">
                                <div class="col-lg-6">

                                    <div class="modal-content cs_modal">
                                        <div class="modal-header">
                                            <h5 class="modal-title">Log in</h5>
                                        </div>
                                        <div class="modal-body">
                                            <form onSubmit={handleLogin}>
                                                <div class="row social_login_btn d-none">
                                                    <div class="form-group col-md-12 text-center">
                                                        <a href="#" class="btn_1 full_width"><i class="fab fa-facebook-square"></i>Log in with Facebook</a>
                                                    </div>
                                                    <div class="form-group col-md-12 text-center">
                                                        <a href="#" class="btn_1 full_width"><i class="fab fa-google"></i>Log in with Google</a>
                                                    </div>
                                                </div>
                                                <div class="border_style ">
                                                    <span>Welcome Back!</span>
                                                </div>
                                                <div class="">
                                                    <input type="text" class="form-control" placeholder="Enter your email" value={username}onChange={(e) => setUsername(e.target.value)}/>
                                                </div>
                                                <div className="form-group mt-2">
                                                                               
                                                                                <div className="input-group">
                                                                                    <input
                                                                                        type={showOldPassword ? 'text' : 'password'}
                                                                                        className="form-control "
                                                                                        id="oldPassword"
                                                                                        placeholder="Password"
                                                                                        value={password} 
                                                                                        onChange={(e) => setPassword(e.target.value)} 
                                                                                        required 
                                                                                        style={{ height: '50px' }} // Increase input height
                                                                                    />
                                                                                    <div className="input-group-append mt-2" style={{position: 'absolute', right: '0rem', zIndex: '100'}}>
                                                                                        <button
                                                                                            type="button"
                                                                                            className="btn"
                                                                                            onClick={() => setShowOldPassword(!showOldPassword)}
                                                                                        >
                                                                                            <i className={showOldPassword ? 'bi bi-eye-slash' : 'bi bi-eye'}></i>
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                <button href="#" class="btn_1 full_width text-center" type='submit' >Log in</button>
                                                <p>Need an account? <Link to="/signup"> Sign Up</Link></p>
                                                <div class="text-center">
                                                    <Link to='/reset-password'  class="pass_forget_btn">Forget Password?</Link>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    );

};

export default Login;
