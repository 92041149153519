import React, { useState } from 'react';
import '../../css/exhibition.css'
import ExhibitionForm from '../components/ExhibitionForm';
import ExhibitionFetch from '../components/exhibitionFetch';
import Exhibitionsingle from '../components/exhibitionSingle';


const Exhibition = () => { 

    const [activeTab, setActiveTab] = useState('HostExhibition');

    const handleTabClick = (tabName) => {
        setActiveTab(tabName);
    };


    return (

        <div className="main_content_iner">
            <div className="container-fluid plr_30 body_white_bg pt_10">
                <div className="row justify-content-center">
                    <div className="d-flex justify-content-between m-2">
                        <div className="d-flex align-items-center gap-3 fs-2 fw-bold">
                            <div className="d-flex mb-1">
                                <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" className="cursor-pointer" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                    <path d="m15 18-6-6 6-6"></path>
                                </svg>
                            </div>

                            <h2 className="text-charcoal fw-bold">Exhibition</h2>
                        </div>
                    </div>
                    <div className='grid lg:grid-cols-[2fr_1fr] gap-0 lg:gap-10'>
                        <div>
                            <div className="tabs rounded">
                                <button
                                    className={`tab-button m-2 ${activeTab === 'HostExhibition' ? 'active' : ''}`}
                                    onClick={() => handleTabClick('HostExhibition')}
                                >
                                    Host Exhibition
                                </button>
                                <button
                                    className={`tab-button m-2 ${activeTab === 'OngoingExhibitions' ? 'active' : ''}`}
                                    onClick={() => handleTabClick('OngoingExhibitions')}
                                >
                                    Ongoing Exhibitions
                                </button>
                            </div>

                            <div className="tab-content" style={{ display: activeTab === 'HostExhibition' ? 'block' : 'none' }}>
                                {/* <h2>Host Exhibition</h2> */}
                                <ExhibitionForm />
                            </div>

                            <div className="tab-content" style={{ display: activeTab === 'OngoingExhibitions' ? 'block' : 'none' }}>
                                {/* <h2>Ongoing Exhibitions</h2> */}
                                <div className="app-container">
                                    <ExhibitionFetch limit={10} page={'true'}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );

};

export default Exhibition;