import React, { useState } from "react";
import axiosInstance from '../api/axiosInstance';
import { toast } from 'react-toastify';

const Editdrop = (props) => {
  const [price, setPrice] = useState(props.price);  // Initialize price state

  const handlePriceChange = (event) => {
    setPrice(event.target.value);
  };

  const handlePriceUpdate = async (event) => {
    event.preventDefault();
    if (!price) {  // Check if the price is empty or invalid
      toast.warning('Please enter a valid price before updating.');
      return;  // Prevent the form submission
    }
    try {
      const response = await axiosInstance.post('main.php?xAction=updatePrice&nftPrice='+ price+ '&id=' + props.id);
      if (response.data.success) {
        toast.success(response.data.message);
        // Optionally reset the price field or other state variables here if needed
        setPrice('');
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error('Error updating price:', error);
      toast.error('Error updating price. Please try again.');
    }
  };

  return (
    <div className="dropdown">
      <button type="button" className="mt-2 peer inline-flex h-5 w-[39px] shrink-0 cursor-pointer items-center rounded-full border-2 border-transparent transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-neutral-950 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 data-[state=unchecked]:bg-neutral-200 dark:focus-visible:ring-neutral-300 dark:focus-visible:ring-offset-neutral-950 dark:data-[state=checked]:bg-neutral-50 dark:data-[state=unchecked]:bg-neutral-800 data-[state=checked]:bg-brand" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <div className="text-brand cursor-pointer">
          <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" className="text-lg mb-1 md:text-xl" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"> <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path><path d="M18.5 2.5a2.12 2.12 0 0 1 3 3L12 15l-4 1 1-4Z"></path></svg>
        </div>
      </button>
      <div className="dropdown-menu p-2" aria-labelledby="dropdownMenuButton">
        <form className="flex flex-col gap-4 md:gap-4" onSubmit={handlePriceUpdate}>
          <div className="space-y-1">
            <label className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70" htmlFor="nftPrice">NFT Price</label>
            <input
              className="flex w-full rounded-lg border border-neutral-200 bg-white px-4 py-2 text-base ring-offset-white file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-neutral-500 focus-visible:outline-none focus-visible:ring-0 focus-visible:border-neutral-500 focus-visible:ring-neutral-400 focus-visible:ring-offset-0 disabled:cursor-not-allowed disabled:opacity-50 dark:border-neutral-800 dark:bg-neutral-950 dark:ring-offset-neutral-950 dark:placeholder:text-neutral-400 dark:focus-visible:ring-neutral-300 h-12"
              step="0.00001"
              placeholder="Edit the NFT price"
              id="nftPrice"
              aria-describedby="nftPrice-description"
              aria-invalid="false"
              type="number"
              value={price}
              name="nftPrice"
              onChange={handlePriceChange}
            />
          </div>
          <button type="submit" className="btn btn-primary inline-flex justify-center rounded-lg ring-offset-white transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-neutral-950 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-25 dark:ring-offset-neutral-950 dark:focus-visible:ring-neutral-300 text-neutral-50 dark:bg-neutral-50 dark:text-neutral-900 dark:hover:bg-neutral-50/90 px-6 py-2 gap-3 items-center h-12 w-full bg-brand font-bold hover:bg-blue-600">
            <span>Update</span>
          </button>
        </form>
      </div>
    </div>
  );
};

export default Editdrop;
