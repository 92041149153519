let CAROUSEL_ITEMS = []; // Initialize carousel items with an empty array
let ART_ITEMS = []; // Initialize art items with an empty array
let GAMING_ITEMS = []; // Initialize gaming items with an empty array
let MEMBERSHIP_ITEMS = []; // Initialize membership items with an empty array
let PFP_ITEMS = []; // Initialize pfp items with an empty array
let PHOTOGRAPHY_ITEMS = []; // Initialize photography items with an empty array
let OTHER_ITEMS = []; // Initialize other items with an empty array
let All = [];

// Define the URL from which to fetch the data
const dataURL = 'https://nftvortex.art/snooart/main.php?xAction=getAllnft';

// Function to fetch data asynchronously
async function fetchData() {
  try {
    // Fetch the data from the data URL
    const response = await fetch(dataURL);
    
    // Check if the response is successful
    if (!response.ok) {
      throw new Error('Failed to fetch data');
    }
    
    // Parse the JSON data from the response
    const responseData = await response.json();
    
    // Filter items based on category
    CAROUSEL_ITEMS = responseData;
    ART_ITEMS = responseData.filter(item => item.category === 'art');
    GAMING_ITEMS = responseData.filter(item => item.category === 'gaming');
    MEMBERSHIP_ITEMS = responseData.filter(item => item.category === 'memberships');
    PFP_ITEMS = responseData.filter(item => item.category === 'pfps');
    PHOTOGRAPHY_ITEMS = responseData.filter(item => item.category === 'photography');
    OTHER_ITEMS = responseData.filter(item => item.category === 'others');
    All = responseData;
    
    // Log the fetched items
    //console.log('Carousel items fetched:', CAROUSEL_ITEMS);
    //console.log('Art items fetched:', ART_ITEMS);
    //console.log('Gaming items fetched:', GAMING_ITEMS);
    //console.log('Membership items fetched:', MEMBERSHIP_ITEMS);
    //console.log('PFP items fetched:', PFP_ITEMS);
    //console.log('Photography items fetched:', PHOTOGRAPHY_ITEMS);
    //console.log('Other items fetched:', OTHER_ITEMS);
  } catch (error) {
    //console.error('Error fetching data:', error.message);
  }
}

// Call the fetch function to populate the data arrays
fetchData();

// Export the data arrays
export { CAROUSEL_ITEMS, ART_ITEMS, GAMING_ITEMS, MEMBERSHIP_ITEMS, PFP_ITEMS, PHOTOGRAPHY_ITEMS, OTHER_ITEMS };
