import Router from './routes';
import { AuthProvider } from './dashboard/context/AuthContext'; // Import AuthProvider




function App() {
  return (
    <>
      <AuthProvider>
        <Router />
      </AuthProvider>
    </>
  );
}


export default App;
