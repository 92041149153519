import React from 'react';
import '../../css/exhibition.css'; // Import the CSS file for styling
import ShowNft from './shownft';
import { useAuth } from '../context/AuthContext';
import { useState,useEffect } from 'react';


const Exhibitionsingle = ({exData,onBackbutton}) => {
    const { defUrllink, fetchUserData } = useAuth();
    const [userdata, setUserData] = useState([]);
    const [data, setData] = useState(null);
    const imagesUrl = defUrllink + 'exhibuploads/';

    async function fetchData() {
        // fetch data    
        const resp = await fetchUserData()
        const UserData = {
            userid: exData.items,
            category: "allnft",
            offset: 0,
            limit: 10,
            mine:true,    
        }; 
        setUserData(resp);
        setData(UserData);
    }

    useEffect(() => {
        fetchData();
      
      }, []);
    return (
        <div className="exhibition-container">
          <button className="back-button" onClick={onBackbutton}>
          <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" height="20" width="20" xmlns="http://www.w3.org/2000/svg">
            <path d="m15 18-6-6 6-6"></path>
          </svg>
            Back to Exhibitions List
            </button>
          <div className="banner-container">
            <img src={imagesUrl + exData.bg_image} alt="Exhibition Banner" className="banner-image" />
            <div className="thumbnail-container">
              <img src={imagesUrl + exData.dp_image} alt="Thumbnail" className="thumbnail-image" />
            </div>
          </div>
          <div className="exhibition-content">
            <h1>{exData.name}</h1>
            <p className="owned-by">Owned by {exData.owner_name}</p>
            <p className="description">
                {exData.description}
             </p>
          </div>
          { data? <ShowNft userData={data} exhibitionData={exData} /> : <>loading....</> }
          
        </div>
      );
    };

export default Exhibitionsingle;
