import React, { useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useAuth } from '../context/AuthContext';
import { toast, ToastContainer } from 'react-toastify';
import axiosInstance from '../api/axiosInstance'; // Import the Axios instance

const AddExhibition = ({exData}) => {
    const [formData, setFormData] = useState({
        name: '',
        description: '',
        joinFee: '',
        backgroundImage: '',
        displayImage: '',
    });
    const [selectedNFTs, setSelectedNFTs] = useState([]);
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
    const [data, setData] = useState([]);
    const { fetchNftData, fetchUserData, defUrllink } = useAuth();

    const fetchData = async () => {
        try {
            const resp = await fetchUserData();
            const UserData = {
                userid: resp.map(user => user.uniqueid),
                category: "",
                offset: 0,
                limit: 1000,
                mine: true,
            };
            if (UserData.userid !== undefined) {
                const response = await fetchNftData(UserData.userid, UserData.category, UserData.offset, UserData.limit);
                setData(response); // Store user data in state
                console.log(response);
            }
        } catch (error) {
            console.error('Fetching data failed:', error);
            // Handle error
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log(formData, selectedNFTs);
        if (selectedNFTs.length < 5) {
            toast.warning('Please select at least 5 NFTs', {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } else {
            const formDataToSend = new FormData();
            formDataToSend.append('linkid', exData.bg_image);
            formDataToSend.append('selectedNFTs', JSON.stringify(selectedNFTs));

            try {
                const response = await axiosInstance.post('main.php?xAction=addExhibition&', formDataToSend, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
                if (response.data.status === 'success') {
                    toast.success(response.data.id, {
                        position: "top-center"
                    });

                    // If you're using a <form> element, reset it directly to also clear file input
                    document.getElementById("uploadForm").reset();
                    return true;
                } else {
                    toast.error(response.data.id, {
                        position: "top-center"
                    });
                    return false;
                }
            } catch (error) {
                console.error('Exhibition failed:', error);
                return false;
            }
        }
    };

    const handleNFTClick = (e, nft) => {
        e.stopPropagation(); // Prevent the dropdown from closing
        setSelectedNFTs((prevSelected) => {
            if (prevSelected.includes(nft.link)) {
                return prevSelected.filter((link) => link !== nft.link);
            } else {
                return [...prevSelected, nft.link];
            }
        });
        setFormData({ ...formData, 'selectedNFTs': selectedNFTs });
    };

    const styles = {
        form: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            maxWidth: isMobile ? '300px' : '660px',
            margin: '0 auto',
            padding: '5px',
            borderRadius: '10px',
        },
        imageUpload: {
            position: 'relative',
            width: '100%',
            height: '200px',
            marginBottom: '20px',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '10px',
            border: '1px dashed #ccc',
        },
        fileInput: {
            position: 'absolute',
            width: '100%',
            height: '100%',
            opacity: 0,
            cursor: 'pointer',
        },
        displayImageContainer: {
            position: 'absolute',
            bottom: '10px',
            left: '10px',
            width: '100px',
            height: '100px',
            backgroundColor: '#fff',
            borderRadius: '10px',
            overflow: 'hidden',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            border: '1px dashed #ccc',
        },
        displayImage: {
            width: '100%',
            height: '100%',
            objectFit: 'cover',
        },
        imagePlaceholder: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '24px',
            color: '#ccc',
        },
        formGroup: {
            width: '100%',
            marginBottom: '15px',
        },
        input: {
            width: '100%',
            padding: '10px',
            fontSize: '16px',
            borderRadius: '5px',
            border: '1px solid #ccc',
        },
        textarea: {
            width: '100%',
            padding: '10px',
            fontSize: '16px',
            borderRadius: '5px',
            border: '1px solid #ccc',
            minHeight: '100px',
        },
        button: {
            padding: '10px 20px',
            fontSize: '16px',
            color: '#fff',
            backgroundColor: '#007bff',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
        },
        Submitbutton: {
            width: '100%',
            padding: '10px 20px',
            fontSize: '16px',
            color: '#fff',
            backgroundColor: '#007bff',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
        },
        nftSection: {
            width: '100%',
            marginBottom: '20px',
            padding: '20px',
            borderRadius: '15px',
            backgroundColor: '#f9f9f9',
        },
        nftHeader: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '10px',
        },
        nftTitle: {
            fontSize: '18px',
            fontWeight: 'bold',
        },
        nftCount: {
            fontSize: '18px',
            fontWeight: 'bold',
            color: '#ff0000',
        },
        nftScroll: {
            display: 'flex',
            overflowX: 'scroll',
            paddingBottom: '10px',
        },
        nftCard: {
            position: 'relative',
            minWidth: '150px',
            maxWidth: '150px',
            height: '250px',
            margin: '0 10px',
            backgroundColor: '#f9f9f9',
            borderRadius: '10px',
            overflow: 'hidden',
            cursor: 'pointer',
            border: '2px solid transparent',
            transition: 'border-color 0.3s',
        },
        nftImage: {
            width: '100%',
            height: '100px',
            objectFit: 'cover',
        },
        nftInfo: {
            padding: '10px',
            backgroundColor: '#fff',
            textAlign: 'center',
        },
        nftTitleText: {
            fontSize: '14px',
            fontWeight: 'bold',
            margin: '5px 0',
            maxWidth: '100%', // Ensure the title doesn't exceed the card width
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
        nftFloor: {
            fontSize: '12px',
            color: '#666',
        },
        nftWeth: {
            fontSize: '12px',
            color: '#666',
        },
        addButton: {
            marginTop: '5px',
            padding: '5px 10px',
            fontSize: '12px',
            color: '#fff',
            backgroundColor: '#007bff',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
        },
    };

    return (
        <div className="dropdown">
            <button id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" height="50" width="50" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5 12h14"></path>
                    <path d="M12 5v14"></path>
                </svg>
            </button>
            <div className="dropdown-menu p-2" aria-labelledby="dropdownMenuButton2">
                <form onSubmit={handleSubmit} style={styles.form}>
                    <div style={styles.nftSection}>
                        <div style={styles.nftHeader}>
                            <h2 style={styles.nftTitle}>Select at least 5 NFTs</h2>
                            <span style={styles.nftCount}>{selectedNFTs.length}</span>
                        </div>
                        <div style={styles.nftScroll}>
                            {data.map((nft, index) => (
                                <div
                                    key={index}
                                    style={{
                                        ...styles.nftCard,
                                        border: selectedNFTs.includes(nft.link) ? '2px solid #007bff' : 'none',
                                    }}
                                    onClick={(e) => handleNFTClick(e, nft)}
                                >
                                    <img src={defUrllink + 'nftuploads/' + nft.link} alt={nft.title} style={styles.nftImage} />
                                    <div style={styles.nftInfo}>
                                        <h3 style={styles.nftTitleText}>{nft.title}</h3>
                                        <p style={styles.nftFloor}>Floor</p>
                                        <p style={styles.nftWeth}>{nft.price} WETH</p>
                                        <span style={styles.addButton}>
                                            {selectedNFTs.includes(nft.link) ? 'Remove' : 'Add'}
                                        </span>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <button type="submit" style={styles.Submitbutton} className='btn btn-lg'>Join Exhibition</button>
                </form>
            </div>
        </div>
    );
};

export default AddExhibition;
