import React, { useState, useEffect } from 'react';
import axiosInstance from '../api/axiosInstance';
import { toast } from 'react-toastify';
import { useAuth } from '../../dashboard/context/AuthContext';
import { useNavigate } from 'react-router';
import 'bootstrap-icons/font/bootstrap-icons.css';
import UserTable from './userTable';

const AdminPage = () => {
    const { listusers } = useAuth();
    const [userData, setUserData] = useState([]);
    const [selectedUser, setSelectedUser] = useState('');
    const [selectedUser2, setSelectedUser2] = useState('');
    const [amount, setAmount] = useState('');
    const [operation, setOperation] = useState('add');
    const [badgeOperation, setBadgeOperation] = useState('add');
    const [selectedBadge, setSelectedBadge] = useState('');
    const navigate = useNavigate();
    const [formData, setFormData] = useState({});
    const [searchTerm, setSearchTerm] = useState('');

    const fetchData = async () => {
        const resp = await listusers();
        if(resp){
        setUserData(resp);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);


    const handleBalanceUpdate = async (e) => {
        e.preventDefault();
        try {
            const response = await axiosInstance.get('main.php?xAction=updatebalance&ethaddress='+selectedUser+'&amount='+amount+'&operation='+operation);
            if (response.data.success) {
                toast.success(response.data.message);
                // Reset form fields
                setSelectedUser('');
                setAmount('');
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error('Error updating balance:', error);
            toast.error('Error updating balance. Please try again.');
        }
    };

    const handleBadgeOperation = async (e) => {
        e.preventDefault();
        try {
// Call API to update badge
            const response = await axiosInstance.get('main.php?xAction=updateBadge&client='+selectedUser2+'&operation='+badgeOperation);
            if (response.data.status) {
                toast.success(response.data.message);
                // Reset form fields
                setSelectedUser('');
                setSelectedBadge('');
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error('Error updating badge:', error);
            toast.error('Error updating badge. Please try again.');
        }
    };

    const handleSearch = (searchValue) => {
        setSearchTerm(searchValue);
        
         // If you have the full list of users stored in userData
         const filteredUsers = userData.filter(user => 
            user.eth_address.toLowerCase().includes(searchValue.toLowerCase().substring(2))
        );
        // If there's only one user in the filtered list, select that user
        if (filteredUsers.length > 0) {
            setSelectedUser(filteredUsers[0].eth_address);
            console.log('Selected user:', filteredUsers[0].eth_address);
        } else {
            setSelectedUser(''); // Reset selected user if multiple or no matches
        }


    }

    return (
        <div className="main_content_iner">
            <div className="container-fluid plr-30 body_white_bg pt-10">
                <div className="row justify-content-center">
                    <div className="col-lg-6 col-md-12 ">
                        <div className="border rounded p-4 m-1">
                        <h2>Update User Balance</h2>
                        <form onSubmit={handleBalanceUpdate}>
                        <div className="form-group mb-4">
                                <label htmlFor="amount">Wallet</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="wallet"
                                    value={searchTerm}
                                    onChange={(e) =>  handleSearch(e.target.value) }
                                    required
                                    style={{ height: '40px' }}
                                />
                            </div>
                            <div className="form-group mb-4">
                                <label htmlFor="user">User</label>
                                <select
                                disabled
                                    className="form-control"
                                    id="user"
                                    value={selectedUser}
                                    onChange={(e) => setSelectedUser(e.target.value)}
                                    required
                                >
                                    <option value="">Wallet user not found</option>
                                    {userData.length > 0 && userData.map((user) => (
                                        <option key={user.id} value={user.eth_address}>
                                            {user.username}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="form-group mb-4">
                                <label htmlFor="amount">Amount</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    id="amount"
                                    value={amount}
                                    onChange={(e) => setAmount(e.target.value)}
                                    step="any"
                                    required
                                    style={{ height: '40px' }}
                                />
                            </div>
                            <div className="form-group mb-4">
                                <label>Operation</label>
                                <div className="form-check form-check-inline">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="operation"
                                        id="addOperation"
                                        value="add"
                                        checked={operation === 'add'}
                                        onChange={() => setOperation('add')}
                                    />
                                    <label className="form-check-label" htmlFor="addOperation">Add</label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="operation"
                                        id="subtractOperation"
                                        value="subtract"
                                        checked={operation === 'subtract'}
                                        onChange={() => setOperation('subtract')}
                                    />
                                    <label className="form-check-label" htmlFor="subtractOperation">Subtract</label>
                                </div>
                            </div>
                            <button type="submit" className="btn btn-primary">
                                Update Balance
                            </button>
                        </form>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12 ">
                    <div className="border rounded p-4 m-1">
                        <h2>Update User Badge</h2>
                        <form onSubmit={handleBadgeOperation}>
                            <div className="form-group mb-4">
                                <label htmlFor="user">User</label>
                                <select
                                    className="form-control"
                                    id="user"
                                    value={selectedUser2}
                                    onChange={(e) => setSelectedUser2(e.target.value)}
                                    required
                                >
                                    <option value="">Select User</option>
                                    {userData.length > 0 && userData.map((user) => (
                                        <option key={user.id} value={user.eth_address}>
                                            {user.username}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            
                            <div className="form-group mb-4">
                                <label>Operation</label>
                                <div className="form-check form-check-inline">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="badgeOperation"
                                        id="addBadgeOperation"
                                        value="add"
                                        checked={badgeOperation === true}
                                        onChange={() => setBadgeOperation(true)}
                                    />
                                    <label className="form-check-label" htmlFor="addBadgeOperation">Add</label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="badgeOperation"
                                        id="removeBadgeOperation"
                                        value="remove"
                                        checked={badgeOperation === false}
                                        onChange={() => setBadgeOperation(false)}
                                    />
                                    <label className="form-check-label" htmlFor="removeBadgeOperation">Remove</label>
                                </div>
                            </div>
                            <button type="submit" className="btn btn-primary">
                                Update Badge
                            </button>
                        </form>
                    </div>
                    </div>
                </div>

                <div class="row justify-content-center m-3">
                <UserTable userData={userData} />
                </div>
            </div>
        </div>
    );
};

export default AdminPage;
