import axios from 'axios';

const dataURL = 'https://api.coincap.io/v2/assets/ethereum';

async function fetchEthToUsdRate() {
  try {
    const response = await axios.get(dataURL);

    if (response.status !== 200) {
      throw new Error('Failed to fetch data');
    }

    const ethData = response.data.data;
    const ethRate = ethData.priceUsd;

    console.log('Ethereum to USD rate fetched:', ethRate);
    return ethRate;
  } catch (error) {
    console.error('Error fetching conversion rate:', error);
    return null;
  }
}

export { fetchEthToUsdRate };
