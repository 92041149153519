import React from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../../dashboard/context/AuthContext";
import Trnxsingle from "../components/trnxSingle";
import { useState, useEffect } from "react";


const DisplayTrnx = (props) => {
    const [data, setData] = useState([]);
    const { fetchTrnx } = useAuth();

    const [pagination, setPagination] = useState({
        offset: 0,
        limit: props.limit,
        page: 1,
    });
    const UserData = {
        userid: '',
        category: '',
        offset: pagination.offset,
        limit: pagination.limit
    };

    const prevPage = () => {
        setPagination(prev => ({
            ...prev,
            offset: Math.max(0, prev.offset - prev.limit),
            page: prev.page - 1
        }));
        fetchData();
    }

    const nextPage = () => {
        setPagination(prev => ({
            ...prev,
            offset: prev.offset + prev.limit,
            page: prev.page + 1
        }));
        fetchData();
    }

    const fetchData = async () => {


        try {
            const response = await fetchTrnx(UserData.offset, props.limit);
            if(props.type !== null && props.type !== 'All'){
               const Filteredresponse = response.filter(item => {
                    if (item.type.toLowerCase().includes(props.type.toLowerCase())) {
                      return true;
                    }
                    return false;
                  });
                  setData(Filteredresponse || []);  // Ensures data is always an array
                  console.log(Filteredresponse);
            }else{
                setData(response || []);  // Ensures data is always an array
            }
            
            
        } catch (error) {
            console.error('Failed to fetch transactions:', error);
            setData([]);  // Ensure data is reset to an empty array on error
        }
    }


    useEffect(() => {
        fetchData();
    }, [pagination]);
  return (
    <> {data.length > 0 ?
        <div className='row p-0 m-1'>
        {data.map((nft) => (
            <Trnxsingle
                type={nft.type}
                status={nft.status}
                amount={nft.amount}
                date={nft.trx_date}
            />
        ))}
        </div>
        : 
        <div class="flex flex-col gap-3 items-center justify-center my-4 md:my-6">
             <p class="italic">no transaction yet.</p>
             <Link class="mt-3" to="/dashboard/deposit">
                 <button class="justify-center font-medium ring-offset-white transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-neutral-950 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-25 dark:ring-offset-neutral-950 dark:focus-visible:ring-neutral-300 text-neutral-50  dark:text-neutral-900 dark:hover:bg-neutral-50/90 h-11 px-6 py-2 flex items-center gap-2 rounded-md w-40 bg-brand hover:bg-brand/80 md:w-64 lg:w-72 bg-secondary">Make a Deposit
                 </button>
             </Link>
         </div>
        }
        <>
       { props.page == 'true' && data.length > 0 ? <nav aria-label="Page navigation example">
            <ul className="pagination justify-content-center">
                <li className="page-item ">
                    <button disabled={pagination.page === 1} className="page-link btn btn-secondary m-1" onClick={prevPage}>Previous</button>
                </li>
                <li className="page-item"><a className="page-link btn btn-outline m-1" >{pagination.page}</a></li>
                <li className="page-item">
                    <button disabled={data.length < pagination.limit} className="page-link btn btn-secondary m-1" onClick={nextPage}>Next</button>
                </li>
            </ul>

        </nav> : <></>}

            </>
    </>
  );
};

export default DisplayTrnx;