import React from 'react';
import { Tabs, Tab, TabList } from 'react-bootstrap';
import DisplayTrnx from './displaytrnx';
import DisplaySales from './displaysales';
import { useNavigate } from "react-router-dom";

const ActivityHistory = () => {
    

    const navigate = useNavigate();
    const goBack = () => {
        navigate(-1); // Navigate back one step
    };


    return (
        <div className="main_content_iner">
            <div className="container-fluid plr_30 body_white_bg pt_10">
                <div className="row justify-content-center">
                    <div className="d-flex justify-content-between m-2">
                        <div className="d-flex align-items-center gap-3 fs-2 fw-bold">
                            <div className="d-flex mb-1" onClick={goBack}>
                                <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" className="cursor-pointer" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                    <path d="m15 18-6-6 6-6"></path>
                                </svg>
                            </div>

                            <h2 className="text-charcoal fw-bold">Activity History</h2>
                        </div>
                    </div>
                    <div className='col-12'>
                        <Tabs defaultActiveKey="all" id="transaction-tabs">
                            <Tab eventKey="all" title="Transaction">
                                <div>
                                    <div class='p-1 single_quick_activity m-3' style={{ backgroundColor: 'rgb(245, 245, 245)', borderRadius: '10px' }}>
                                        <div className="flex flex-col gap-4">
                                        <DisplayTrnx limit={10} page={'true'} type={'All'} />
                                        </div>
                                    </div>
                                </div>

                            </Tab>

                            <Tab eventKey="deposit" title="Sales">
                            <div>
                                    <div class='p-1 single_quick_activity m-3' style={{ backgroundColor: 'rgb(245, 245, 245)', borderRadius: '10px' }}>



                                       
                                        <div className="flex flex-col gap-4">
                            <DisplaySales limit={10} page={'true'} type={'All'} />
                            </div>

                                    </div>
                                </div>
                            </Tab>


                        </Tabs>
                    </div>




                </div>
            </div>
        </div>
    );
};

export default ActivityHistory;
