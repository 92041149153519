import React from 'react';
import { useState, useEffect } from 'react';
import axiosInstance from '../api/axiosInstance'; // Import the Axios instance
import { toast, ToastContainer } from 'react-toastify';
import Copy from '../api/copy';
import { useAuth } from '../../dashboard/context/AuthContext';
import { useNavigate } from 'react-router';
import 'bootstrap-icons/font/bootstrap-icons.css';

const Settings = () => {
    const { logout,defUrllink, fetchUserData } = useAuth();
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [showOldPassword, setShowOldPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);
    const [userData , setUserData] = useState();
    const navigate = useNavigate();
    const goBack = () => {
        navigate(-1); // Navigate back one step
    };

    const changePassword = async () => {
        // Add your logic here to handle password change
        console.log('Changing password...');
        console.log('Old Password:', oldPassword);
        console.log('New Password:', newPassword);
        console.log('Confirm New Password:', confirmNewPassword);

                // Add your logic here to handle password change
            const formDataToSend = new FormData();
            
            formDataToSend.append('oldpass', oldPassword);
            formDataToSend.append('newpass', newPassword);
        
            try {
                // Make API call to change password using formDataToSend
                const response = await axiosInstance.post('main.php?xAction=changepassword&', formDataToSend, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
                if (response.data.success === 'true') {
                   
                    toast.success(response.data.message, {
                        position: "top-center"
                      });

                    // Reset form fields
                    setOldPassword('');
                    setNewPassword('');
                    setConfirmNewPassword('');
                } else {
                    toast.error(response.data.message, {
                        position: "top-center"
                      });

                }
                console.log('Password change response:', response.data);
            } catch (error) {
                console.error('Password change failed:', error);
                // Handle error
                // Show error message to user
            }
       
        
    };
    const [formData, setFormData] = useState({
        username: userData ? userData.map(user => user.username) : '',
        backgroundPics: '',
        displayPics: '',
        bio: userData ? userData.map(user => user.bio) : '',

    });
    const [uploadError, setUploadError] = useState(null); // State to store upload error

    const handleChange = (e) => {
        const { name, value, files } = e.target;
        if (name === 'backgroundPics' || name === 'displayPics') { 
            setFormData((prevFormData) => ({
                ...prevFormData,
                [name]: files[0]
            }));
        } else {
            setFormData((prevFormData) => ({
                ...prevFormData,
                [name]: value
            }));
        }
    };

    async function fetchData() {
        // fetch data    
        const resp = await fetchUserData();
        setUserData(resp);
        formData.username = resp.map(user => user.username);
        formData.id = resp.map(user => user.id);
        formData.bio = resp.map(user => user.bio);
        formData.backgroundPics = resp.map(user =>
            user.cover_photo ? defUrllink + '/useruploads/' + user.cover_photo : '../img/logo.png'
          );
          
        formData.displayPics = resp.map(user =>
            user.profile_photo ? defUrllink + '/useruploads/' + user.profile_photo : '../img/client_img.png'
          );
          

    }
  

    useEffect(() => {
        fetchData();
      
      }, []);

  

    const handleSubmit = async (e) => {
        e.preventDefault();
        const { username, backgroundPics, displayPics, bio } = formData;

        // Validate form fields
        // if (!username || !backgroundPics || !displayPics || !bio) {
        //     alert('Please fill in all fields.');
        //     return;
        // }
        if (username || backgroundPics || displayPics || bio) {
            // alert('Please fill in all fields.');
            // return;
        }else{
            return;
        }

        // Send user data to backend
        const formDataToSend = new FormData();
        formDataToSend.append('username', username);
        formDataToSend.append('backgroundPics', backgroundPics);
        formDataToSend.append('displayPics', displayPics);
        formDataToSend.append('bio', bio);



        try {
            // Make API call to upload NFT using formDataToSend
            const response = await axiosInstance.post('main.php?xAction=updateProfile&', formDataToSend, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            if (response.data.status === 'success') {
                toast.success(response.data.id, {
                    position: "top-center"
                  });
                //resetForm();
            } else {
                toast.error(response.data.id, {
                    position: "top-center"
                  });
            }
            console.log('Profile upload response:', response.data.id);
           
            // Handle success
            // Redirect user or show success message
        } catch (error) {
            console.error('Profile upload failed:', error);
            setUploadError('Profile upload failed. Please try again.');
        }
    };



    return (
        <div className="main_content_iner ">
            <div className="container-fluid plr_30 body_white_bg pt_10">
                <div className="row justify-content-center">
                    <div className="d-flex justify-content-between m-2">
                        <div className="d-flex align-items-center gap-3 fs-2 fw-bold">
                            <div className="d-flex mb-1" onClick={goBack}>
                            <button  class="mb-2 justify-center rounded-lg font-medium ring-offset-white transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-neutral-950 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-25 dark:ring-offset-neutral-950 dark:focus-visible:ring-neutral-300 dark:bg-neutral-50 dark:text-neutral-900 dark:hover:bg-neutral-50/90 py-2 flex items-center gap-1 text-sm text-charcoal h-9 px-2 bg-light-100 hover:bg-light-200"><svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" height="20" width="20" xmlns="http://www.w3.org/2000/svg"><path d="m15 18-6-6 6-6"></path></svg><div class="mr-1">Back</div></button>
                            </div>

                            <h2 className="text-charcoal fw-bold d-none">Settings</h2>
                        </div>

                    </div>
                    {/* maincode */}

                    <div className=" justify-content-center ">
                        <div className="row">

                            <div className="col-lg-6 col-md-12">
                                {/* for support id */}
                                <div class="single_element">
                                    <div class="quick_activity">
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="">
                                                    <div class="" style={{ backgroundColor: 'rgb(245, 245, 245)', borderRadius: '20px' }}>
                                                        <div className='row'>
                                                            <div class=" text-charcoal font-bold p-3 m-3">
                                                                <h4 style={{ color: 'black', }}>Support ID</h4>
                                                                <section class="flex flex-col gap-8 md:gap-10 m-1 form-group">
                                                                   
                                                                    <div class="flex flex-col gap-2">
                                                                        <div class="flex items-center gap-2">
                                                                            <input class=" rounded" style={{width: '75%'}} disabled="" placeholder="Support ID" type="text" value={"RATI-" + formData.id} />
                                                                            <button class="btn btn-primary " type="button">
                                                                                <Copy text={"RATI-" + formData.id} />
                                                                            </button>
                                                                        </div>
                                                                       
                                                                    </div>

                                                                </section>

                                                            </div>


                                                        </div>
                                                    </div>



                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* for profile  */}

                                <div class="single_element mt-2 mb-2">
                                    <div class="quick_activity">
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="">
                                                    <div class="p-4" style={{ backgroundColor: 'rgb(245, 245, 245)', borderRadius: '10px' }}>
                                                        <div class=" d-flex justify-content-between align-items-end ">
                                                            <h4 className='m-3 text-charcoal font-bold' style={{ color: 'black' }}>Profile</h4>
                                                           
                                                        </div>


                                                        <form class="" onSubmit={handleSubmit}>
                                                            
                                                            <div class="profile_card_5">
                                                                <div class="cover-photo" style={{ background: `url(${formData.backgroundPics}) center center / cover no-repeat` }}>
                                                                <div className="upload-box w-full max-w-none" style={{position: 'absolute', height: '160px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                                                    <input type="file" id="backgroundImage" accept="image/*" name='backgroundPics' onChange={handleChange}  style={{ display: 'none' }} />
                                                                    <label htmlFor="backgroundImage" className="w-100 h-100 cursor-pointer rounded p-3 w-full flex flex-col items-center justify-center">
                                                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                                                                            <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" className="fs-3 text-mid-200" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg" style={{  marginTop: '40px',  }}>
                                                                                <path d="M21 12v7a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h7"></path>
                                                                                <line x1="16" x2="22" y1="5" y2="5"></line>
                                                                                <line x1="19" x2="19" y1="2" y2="8"></line>
                                                                                <circle cx="9" cy="9" r="2"></circle>
                                                                                <path d="m21 15-3.086-3.086a2 2 0 0 0-2.828 0L6 21"></path>
                                                                            </svg>
                                                                        </div>
                                                                    </label>
                                                                </div>
                                                                <input type="file" id="displayImage" accept="image/*" name="displayPics" onChange={handleChange}  style={{ display: 'none' }} />
                                                                   
                                                                        <label htmlFor="displayImage">
                                                                        
                                                                        <div style={{  display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                                                                        <img  src={formData.displayPics}  class="profile" />
                                                                            <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" className="fs-3 text-mid-200" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg" style={{ margin: 'auto',position: 'absolute', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', marginTop: '80px'}}>
                                                                                <path d="M21 12v7a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h7"></path>
                                                                                <line x1="16" x2="22" y1="5" y2="5"></line>
                                                                                <line x1="19" x2="19" y1="2" y2="8"></line>
                                                                                <circle cx="9" cy="9" r="2"></circle>
                                                                                <path d="m21 15-3.086-3.086a2 2 0 0 0-2.828 0L6 21"></path>
                                                                            </svg>
                                                                            
                                                                        </div>
                                                                        </label>
                                                                   
                                                                </div>

                                                                <div class="mb-3 mt-4">
                                                                    <label class="form-label" for="exampleFormControlInput1">Username</label>
                                                                    <input type="text" name='username' value={formData.username} onChange={handleChange} required class="form-control" id="exampleFormControlInput1"  />
                                                                </div>

                                                                <div class="form-group mt-2">
                                                                        <label for="nftDescription" class="form-label">Bio</label>
                                                                        <textarea class="form-control" name="bio" onChange={handleChange}
                                                                            value={formData.bio}                                                                          
                                                                       rows="3" placeholder="Provide a detailed description of your NFT." ></textarea>
                                                                </div>

                                                                <section className="d-flex justify-content-end">
                                                                    <button className="btn btn-lg btn-primary" style={{ margin: '10px' }}>
                                                                        Update
                                                                    </button>
                                                                </section>
                                                                
                                                            </div>
                                                        </form>


                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div className="col-lg-6 col-md-12">
                            <div class="single_element">
                                    <div class="quick_activity">
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="">
                                                    <div class=" p-1" style={{ backgroundColor: 'rgb(245, 245, 245)',  borderRadius: '10px'}}>
                                                        <div className='row'>
                                                            <div class="text-charcoal font-bold p-3  m-1">
                                                                <h4 style={{ color: 'black', }}>Reset Password</h4>
                                                                <section class="flex flex-col gap-8 md:gap-10 m-1 form-group">
                                                                <form onSubmit={(e) => { e.preventDefault(); changePassword(); }}>
                                                                            <div className="form-group mt-2">
                                                                                <label htmlFor="oldPassword" className="form-label">Old Password</label>
                                                                                <div className="input-group">
                                                                                    <input
                                                                                        type={showOldPassword ? 'text' : 'password'}
                                                                                        className="form-control rounded-pill"
                                                                                        id="oldPassword"
                                                                                        placeholder="Old Password"
                                                                                        value={oldPassword} 
                                                                                        onChange={(e) => setOldPassword(e.target.value)} 
                                                                                        required 
                                                                                        style={{ height: '50px' }} // Increase input height
                                                                                    />
                                                                                    <div className="input-group-append mt-2" style={{position: 'absolute', right: '0rem', zIndex: '100'}}>
                                                                                        <button
                                                                                            type="button"
                                                                                            className="btn"
                                                                                            onClick={() => setShowOldPassword(!showOldPassword)}
                                                                                        >
                                                                                            <i className={showOldPassword ? 'bi bi-eye-slash' : 'bi bi-eye'}></i>
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="form-group mt-2">
                                                                                <label htmlFor="newPassword" className="form-label">New Password</label>
                                                                                <div className="input-group">
                                                                                    <input
                                                                                        type={showNewPassword ? 'text' : 'password'}
                                                                                        className="form-control rounded-pill"
                                                                                        id="newPassword"
                                                                                        placeholder="New Password"
                                                                                        value={newPassword} 
                                                                                        onChange={(e) => setNewPassword(e.target.value)} 
                                                                                        required 
                                                                                        style={{ height: '50px' }} // Increase input height
                                                                                    />
                                                                                    <div className="input-group-append mt-2" style={{position: 'absolute', right: '0rem', zIndex: '100'}}>
                                                                                        <button
                                                                                            type="button"
                                                                                            className="btn"
                                                                                            onClick={() => setShowNewPassword(!showNewPassword)}
                                                                                        >
                                                                                            <i className={showNewPassword ? 'bi bi-eye-slash' : 'bi bi-eye'}></i>
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="form-group mt-2">
                                                                                <label htmlFor="confirmNewPassword" className="form-label">Confirm New Password</label>
                                                                                <div className="input-group">
                                                                                    <input
                                                                                        type={showConfirmNewPassword ? 'text' : 'password'}
                                                                                        className="form-control rounded-pill"
                                                                                        id="confirmNewPassword"
                                                                                        placeholder="Confirm New Password"
                                                                                        value={confirmNewPassword} 
                                                                                        onChange={(e) => setConfirmNewPassword(e.target.value)} 
                                                                                        required 
                                                                                        style={{ height: '50px' }} // Increase input height
                                                                                    />
                                                                                    <div className="input-group-append mt-2" style={{position: 'absolute', right: '0rem', zIndex: '100'}}>
                                                                                        <button
                                                                                            type="button"
                                                                                            className="btn"
                                                                                            onClick={() => setShowConfirmNewPassword(!showConfirmNewPassword)}
                                                                                        >
                                                                                            <i className={showConfirmNewPassword ? 'bi bi-eye-slash' : 'bi bi-eye'}></i>
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <button className="btn btn-primary btn-lg text-center form-group mt-2">SEND</button>
                                                                        </form>



                                                                </section>

                                                            </div>


                                                        </div>
                                                    </div>



                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Settings;