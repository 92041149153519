import React from "react";
import { useState, useEffect } from 'react';
import { useAuth } from '../context/AuthContext';
import Swal from 'sweetalert2';
import axiosInstance from '../api/axiosInstance';
import DisplayTrnx from './displaytrnx';
import DisplaySales from './displaysales';
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { toast, } from 'react-toastify';


const Withdraw = () => {
    const [userdata, setUserData] = useState([]);
    const { userData, fetchUserData } = useAuth();
    const navigate = useNavigate();
    const goBack = () => {
        navigate(-1); // Navigate back one step
    };


    const [formData, setFormData] = useState({
        type: 'weth',
        network: 'ERC-20',
        address: '',
        amount: '',

    });
    const handleChange = (e) => {
        const { name, value, files } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: name === 'image' ? files[0] : value,
        }));
    };

    const withdraw = async () => {
        const { type, network, address, amount } = formData;
        try {
            // Validate data
            if (!type || !network || !address || !amount) {
                throw new Error("Please fill in all fields.");
            }

            // Additional validation can be added as per requirements
            const regex = /^[0-9]*\.?[0-9]*$/;

            if (amount === '' || regex.test(amount)) {
                if (isNaN(parseFloat(amount)) || parseFloat(amount) <= 0) {
                    throw new Error("Please enter a valid amount.");
                }
            }

            // Perform withdrawal
            const token = localStorage.getItem('token');
            if (!token) {
                throw new Error("Token not found.");
            }

            const formDataToSend = new FormData();
            formDataToSend.append('type', type);
            formDataToSend.append('network', network);
            formDataToSend.append('address', address);
            formDataToSend.append('amount', amount);


            const response = await axiosInstance.post('main.php?xAction=withdrawal&', formDataToSend, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            const responseData = response.data;

            if (responseData.success) {
                // Handle successful withdrawal
                console.log('Withdrawal successful:', responseData.message);
                Swal.fire({
                    title: '',
                    html: `<section class="flex flex-col items-center gap-12 py-12 px-6"><div class="flex flex-col items-center gap-4"><div class="flex flex-col items-center gap-4"><svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" class="text-green-600" height="120" width="120" xmlns="http://www.w3.org/2000/svg"><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><polyline points="22 4 12 14.01 9 11.01"></polyline></svg><p class="text-green-600 font-bold text-xl text-center">Withdrawal Submitted</p></div><p class="text-center">Your withdrawal has been successfully submitted. You may visit History to check the order status.</p></div><div class="w-full"><a href="/dashboard/activity-history"><button class="inline-flex items-center justify-center rounded-lg ring-offset-white transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-neutral-950 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-25 dark:ring-offset-neutral-950 dark:focus-visible:ring-neutral-300 text-neutral-50 dark:bg-neutral-50 dark:text-neutral-900 dark:hover:bg-neutral-50/90 px-6 py-2 h-12 w-full bg-brand font-bold hover:bg-brand/80">View History</button></a></div></section>`,
                    position: "center",
                    showCloseButton: true,
                showCancelButton: false,
                showConfirmButton: false,
                position: 'centre',
                buttons: [],
                customClass: {
                    closeButton: 'swal-close-button',
                    container: 'fullscreen-swal'
                },
                });
                await fetchUserData();
            } else {
                // Handle withdrawal failure
                console.error('Withdrawal failed:', responseData.error);
               
                toast.error(responseData.message, {
                    position: "top-right"
                  });
            }
        } catch (error) {
            // Handle validation errors and other errors
            console.error('Withdrawal error:', error.message);
            toast.error(error.message, {
                position: "top-right"
              });
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        withdraw();
    };

    async function fetchData() {
        // fetch data    
        const resp = await fetchUserData();
        setUserData(resp);

    }


    useEffect(() => {
        fetchData();

    }, []);



    return (
        <div class="main_content_iner ">
            <div class="container-fluid plr_30 body_white_bg pt_10">
                <div class="row justify-content-center">

                    <div class="text-2xl md:text-3xl mt-4"> <button onClick={goBack} class="mb-2 justify-center rounded-lg font-medium ring-offset-white transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-neutral-950 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-25 dark:ring-offset-neutral-950 dark:focus-visible:ring-neutral-300 dark:bg-neutral-50 dark:text-neutral-900 dark:hover:bg-neutral-50/90 py-2 flex items-center gap-1 text-sm text-charcoal h-9 px-2 bg-light-100 hover:bg-light-200"><svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" height="20" width="20" xmlns="http://www.w3.org/2000/svg"><path d="m15 18-6-6 6-6"></path></svg><div class="mr-1">Back</div></button></div>

                    <div class="row justify-content-center">
                        <div className="col-lg-12 col-xl-8">

                            <div class="single_element">
                                <div class="quick_activity">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="">
                                                <div class=" round" >
                                                    <section class="py-6 md:py-8 m-3">
                                                        <form className="flex flex-col gap-8 md:gap-12" onSubmit={handleSubmit}>
                                                            <div className="form-group">
                                                                <label className="form-label">Coin</label>
                                                                <select className="form-select" name="type" value={formData.type} onChange={handleChange}>
                                                                    <option value="eth" data-content="<img src='img/eth.webp' width='20px'/> ETH">ETH</option>
                                                                    <option value="weth" data-content="<img src='img/weth.webp' width='20px'/> wETH">wETH</option>

                                                                </select>
                                                            </div>

                                                            <div className="row">
                                                                <div className="col-12">
                                                                    <div className="form-group m-1">
                                                                        <label className="form-label">Network</label>
                                                                        <input className="form-control" readOnly value={formData.network} />
                                                                    </div>
                                                                    <div className="form-group m-1 mt-2">
                                                                        <div className="row">
                                                                            <label className="form-label col">Wallet Address</label>
                                                                            <div className="cursor-pointer col-3">
                                                                                {/* Your icon here */}
                                                                            </div>
                                                                        </div>
                                                                        <input className="form-control" name="address" placeholder="Enter/Paste wallet address" value={formData.address} onChange={handleChange} />
                                                                    </div>
                                                                    <div className="form-group m-1">
                                                                        <label className="form-label">Amount</label>
                                                                        <input className="form-control" step="0.00001" type="number" name="amount" placeholder="Enter amount" value={formData.amount} onChange={handleChange} />
                                                                        <div className="d-flex flex-row mt-1">
                                                                            <div>Avl bal: </div>
                                                                            <span className=""><span className="font-weight-bold"> {userdata.map(user => user.weth_balance)}</span> wETH</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="d-flex justify-content-end">
                                                                <button type="submit" className="btn btn-primary">
                                                                    <span>Continue</span>
                                                                </button>
                                                            </div>
                                                        </form>
                                                    </section>

                                                </div>



                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="col-lg-12 col-xl-4 mt-sm-1 mt-2 mt-lg-0 quick_activity" >
                            <div class='p-1 single_quick_activity' style={{ backgroundColor: 'rgb(245, 245, 245)', borderRadius: '10px' }}>



                                <div class=" d-flex justify-content-between align-items-end ">
                                    <h6 className='m-3' style={{ color: 'black', fontWeight: 300 }}>Recent Transactions</h6>
                                    <Link to='/dashboard/activityHistory' type="button" className="btn btn-secondary btn-sm m-3" style={{ backgroundColor: '#f5f5f5', color: 'black' }}>View all</Link>
                                </div>
                                <div className="flex flex-col gap-4">
                                <DisplayTrnx limit={10} page={'false'} />


                                </div>

                            </div>

                            <div class='p-1 mt-3 single_quick_activity' style={{ backgroundColor: 'rgb(245, 245, 245)', borderRadius: '10px' }}>


                                <div class=" d-flex justify-content-between align-items-end ">
                                    <h6 className='m-3' style={{ color: 'black', fontWeight: 300 }}>Recent Transactions</h6>
                                    <Link to='/dashboard/activityHistory' type="button" className="btn btn-secondary btn-sm m-3" style={{ backgroundColor: '#f5f5f5', color: 'black' }}>View all</Link>
                                </div>
                                <div className="flex flex-col gap-4">
                                <DisplaySales limit={5} page={'false'}/>




                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div >
    )

}
export default Withdraw;