import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../dashboard/context/AuthContext'; 
import { useState, useEffect } from 'react';


const Header = () => {
    const { logout,defUrllink, fetchUserData } = useAuth();
    const [userData , setUserData] = useState();
    const [formData, setFormData] = useState({
        username: userData ? userData.map(user => user.username) : '',
        backgroundPics: '',
        displayPics: '',
        bio: userData ? userData.map(user => user.bio) : '',

    });
    async function fetchData() {
        try {
            const resp = await fetchUserData();
            if (resp) {
                setUserData(resp);
                setFormData({
                    ...formData,
                    username: resp.map(user => user.username),
                    id: resp.map(user => user.id),
                    bio: resp.map(user => user.bio),
                    backgroundPics: resp.map(user =>
                        user.cover_photo ? defUrllink + '/useruploads/' + user.cover_photo : '../img/logo.png'
                    ),
                    displayPics: resp.map(user =>
                        user.profile_photo ? defUrllink + '/useruploads/' + user.profile_photo : '../img/client_img.png'
                    )
                });
            } else {
                // Handle case when resp is null or undefined
                console.error('Failed to fetch user data');
            }
        } catch (error) {
            console.error('Error fetching user data:', error);
        }
    }
    

    useEffect(() => {
        fetchData();
      
      }, []);
    
    const toggleSidebar = () => {
        
        document.querySelector(".sidebar_icon").addEventListener("click", function () {
            document.querySelector(".sidebar").classList.toggle("active_sidebar");
        });

        document.querySelector(".sidebar_close_icon i").addEventListener("click", function () {
            document.querySelector(".sidebar").classList.remove("active_sidebar");
        });
    };

    return (
        
        <div className="container-fluid g-0">
            <div className="row">
                <div className="col-lg-12 p-0">
                    <div className="header_iner d-flex justify-content-between align-items-center">
                        <div className="sidebar_icon d-lg-none" onClick={toggleSidebar}>
                            <i className="ti-menu"></i>
                        </div>
                        <div className="logo d-flex justify-content-between d-lg-none" style={{position:'absolute', left:'70px', top:'10px', }}>
                            <Link to="/"><img src="../img/logo.png" alt="" style={{height: '60px'}} /></Link>
                            
                        </div>
                        <div class="serach_field-area">
                        <Link to="/"><img src="../img/logo.png" alt="" /></Link>
                        </div>

                        <div className="header_right d-flex justify-content-between align-items-center">
                            <div className="profile_info" style={{height: '40px', height : '40px'}}>
                                <Link to={'/dashboard/settings'}>
                                
                                <img   src={formData.displayPics} alt="#" />
                                {/* <div className="profile_info_iner">
                                    <p>Welcome</p>
                                    <h5>{userData ? userData.map(user => user.username) : <></>}</h5>
                                    <div className="profile_info_details">
                                        <Link to="/dashboard/settings">My Profile <i className="ti-user"></i></Link>
                                        
                                        <button onClick={logout}>Log Out <i className="ti-shift-left"></i></button>
                                    </div>
                                </div> */}
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

};




export default Header;