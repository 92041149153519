import React from 'react';
import { NavLink } from 'react-router-dom';
import { useAuth } from '../../dashboard/context/AuthContext';

const Sidebar = () => {
    const { logout } = useAuth();
    return (
        <nav className="sidebar">
            <div className="logo d-flex justify-content-between">
                <NavLink to="/" activeClassName="active-link">
                    <img src="../img/logo.png" alt="" />
                </NavLink>
                <div className="sidebar_close_icon d-lg-none">
                    <i className="ti-close"></i>
                </div>
            </div>
            <ul id="sidebar_menu">
                <li>
                    <NavLink to="/dashboard" activeClassName="active-link" className="has-arrow" aria-expanded="false">
                        <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" height="20" width="20" xmlns="http://www.w3.org/2000/svg">
                            <path d="M21 12V7H5a2 2 0 0 1 0-4h14v4"></path>
                            <path d="M3 5v14a2 2 0 0 0 2 2h16v-5"></path>
                            <path d="M18 12a2 2 0 0 0 0 4h4v-4Z"></path>
                        </svg>
                        <span>Account Summary</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/dashboard/mycollection" activeClassName="active-link" className="has-arrow" aria-expanded="false">
                        <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" height="20" width="20" xmlns="http://www.w3.org/2000/svg">
                            <path d="M2 7v10"></path>
                            <path d="M6 5v14"></path>
                            <rect width="12" height="18" x="10" y="3" rx="2"></rect>
                        </svg>
                        <span>My collection</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/dashboard/create-nft" activeClassName="active-link" className="has-arrow" aria-expanded="false">
                        <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" height="20" width="20" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
                            <path d="M18.5 2.5a2.12 2.12 0 0 1 3 3L12 15l-4 1 1-4Z"></path>
                        </svg>
                        <span>Create NFT</span>
                    </NavLink>
                </li>
                {/* <li>
                    <NavLink to="/dashboard/activity-history" activeClassName="active-link" className="has-arrow" aria-expanded="false">
                        <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" height="20" width="20" xmlns="http://www.w3.org/2000/svg">
                            <rect width="20" height="14" x="2" y="5" rx="2"></rect>
                            <line x1="2" x2="22" y1="10" y2="10"></line>
                        </svg>
                        <span>Activity History</span>
                    </NavLink>
                </li> */}
                <li>
                    <NavLink to="/dashboard/transaction-history" activeClassName="active-link" className="has-arrow" aria-expanded="false">
                    <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" height="20" width="20" xmlns="http://www.w3.org/2000/svg"><path d="m16 3 4 4-4 4"></path><path d="M20 7H4"></path><path d="m8 21-4-4 4-4"></path><path d="M4 17h16"></path></svg>
                        <span>Transactions History</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/dashboard/sales-history" activeClassName="active-link" className="has-arrow" aria-expanded="false">
                        <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" height="20" width="20" xmlns="http://www.w3.org/2000/svg">
                            <rect width="20" height="14" x="2" y="5" rx="2"></rect>
                            <line x1="2" x2="22" y1="10" y2="10"></line>
                        </svg>
                        <span>Sales History</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/dashboard/marketplace" activeClassName="active-link" className="has-arrow" aria-expanded="false">
                        <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" height="20" width="20" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6 2 3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4Z"></path>
                            <path d="M3 6h18"></path>
                            <path d="M16 10a4 4 0 0 1-8 0"></path>
                        </svg>
                        <span>Marketplace</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/dashboard/exhibition" activeClassName="active-link" className="has-arrow" aria-expanded="false">
                        <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" height="20" width="20" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7 21h10"></path>
                            <rect width="20" height="14" x="2" y="3" rx="2"></rect>
                        </svg>
                        <span>Exhibition</span>
                    </NavLink>
                </li>
                <li  className='d-none'>
                    <NavLink to="/dashboard/auction" activeClassName="active-link" className="has-arrow" aria-expanded="false">
                        <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" height="20" width="20" xmlns="http://www.w3.org/2000/svg">
                            <path d="m14 13-7.5 7.5c-.83.83-2.17.83-3 0 0 0 0 0 0 0a2.12 2.12 0 0 1 0-3L11 10"></path>
                            <path d="m16 16 6-6"></path>
                            <path d="m8 8 6-6"></path>
                            <path d="m9 7 8 8"></path>
                            <path d="m21 11-8-8"></path>
                        </svg>
                        <span>Auction</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink onClick={logout} activeClassName="active-link" className="has-arrow" aria-expanded="false">
                        <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" height="20" width="20" xmlns="http://www.w3.org/2000/svg"><path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path><polyline points="16 17 21 12 16 7"></polyline><line x1="21" x2="9" y1="12" y2="12"></line></svg>
                        <span>Logout</span>
                    </NavLink>
                </li>
            </ul>
        </nav>
    );
};

export default Sidebar;
