import React from "react";
import { PAGE_ITEMS } from "../consts";

import LayoutContainer from "../components/LayoutContainer";

const Photography = () => {
    const Item = PAGE_ITEMS.find(item => item.name === 'Photography');

    return (
       <LayoutContainer Data={Item} />
    )
}

export default Photography;