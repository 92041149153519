import React from "react"
import ShowNft from "../components/shownft"
import { useAuth } from '../context/AuthContext';
import { useState, useEffect } from 'react';


const Marketplace = () => {
    const { userData, fetchUserData } = useAuth();
    


    const [data, setData] = useState(null);
    




    async function fetchData() {
        try {
            const resp = await fetchUserData();
            if (resp !== null) {
                const UserData = {
                    userid: "",
                    category: "",
                    offset: 0,
                    limit: 21,
                    page: true,
                    mine: false,
                };
                setData(UserData);
            }
        } catch (error) {
            console.error("Error fetching user data:", error);
        }
    }

    useEffect(() => {
        fetchData();
    }, []);

    
    return (
        <div class="main_content_iner ">
            <div class="container-fluid plr_30  pt_10">
                <div class="row justify-content-center">

                    {/* header for market alce */}
                    <div class="white_box">
                        <div class="box_header ">

                        </div>
                        <div class="pCard_card" style={{ boxShadow: '0px 0px 20px #ffff', background: 'transparent !important' }}>
                            <div class="pCard_up" style={{ backgroundImage: 'url(../gaming-banner.jpg)' }}>
                                <div class="pCard_text">
                                    <h2>Marketplace</h2>
                                    <p>Items 128,506 &amp; Chain Ethereum</p>
                                </div>
                                <div class="pCard_add" style={{background : 'white'}}><svg stroke="currentColor" className="mt-3 m-4" fill="none" stroke-width="1" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" height="40" width="40" xmlns="http://www.w3.org/2000/svg"><path d="M6 2 3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4Z"></path><path d="M3 6h18"></path><path d="M16 10a4 4 0 0 1-8 0"></path></svg></div>
                            </div>
                            <div class="pCard_down" style={{ backgroundColor: 'transparent', height: '150px' }}>
                                <div>
                                    <p>Total Volume</p>
                                    <p>24188.0849 ETH</p>
                                </div>
                                <div>
                                    <p>Listed</p>
                                    <p>86%</p>
                                </div>
                                <div>
                                    <p>Owners</p>
                                    <p>9782</p>
                                </div>

                            </div>

                        </div>
                        <div class="">
                            <p><span class="line-clamp-none">Explore a vibrant digital marketplace where creators and collectors come together to buy, sell, and trade unique NFTs. Discover a wide range of digital assets, including art, collectibles, music, videos, and more, all authenticated on the blockchain for proven ownership and rarity.</span></p>

                        </div>
                    </div>
                    {/* content for marketyplace */}

                    <div className="row">
                        {/* map through nfts */}
                        <div class='d-none'>
                        
                        </div>
                        {data ? <ShowNft userData={data} /> : <>loading...</>} 
                        


                    </div>

                </div>
            </div>
        </div>


    )
}
export default Marketplace