import React, { useState } from 'react';
import { useAuth } from '../context/AuthContext';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'bootstrap-icons/font/bootstrap-icons.css';
import Header from '../../landing/components/Header';

const Register = () => {

    const [formData, setFormData] = useState({
        username: '',
        email: '',
        password: '',
        confirmPassword: '',
        agreeToTerms: false,
    });

    const [error, setError] = useState(null); // State to store registration error
    const { register } = useAuth();
    const [showOldPassword, setShowOldPassword] = useState(false);
    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: type === 'checkbox' ? checked : value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validate form fields
        if (!formData.agreeToTerms) {
            //alert('Please agree to terms and policies.');
            toast.warning('Please agree to terms and policies.', {
                position: "top-right"
            });
            return;
        }
        if (formData.password !== formData.confirmPassword) {
            //alert('Passwords do not match.');
            toast.warning('Passwords do not match.', {
                position: "top-right"
            });
            return;
        }
        // Send registration request
        // Replace with your registration API endpoint and logic

        try {
            await register(formData.username, formData.email, formData.password);
            // Redirect user to dashboard or any other page upon successful registration
        } catch (error) {
            console.error('Registration failed:', error);
            setError('Registration failed. Please try again.');
        }
        console.log('Form submitted:', formData);
    };

    return (
        <>
        <Header />
        <div className="main_content_iner ">
            <div class="container-fluid plr_30 body_white_bg pt_30">
                <div class="row justify-content-center">
                    <div class="col-lg-12">
                        <div class="white_box mb_30">
                            <div class="row justify-content-center">
                                <div class="col-lg-6">

                                    <div class="modal-content cs_modal">
                                        <div class="modal-header">
                                            <h5 class="modal-title">Sign Up</h5>
                                        </div>
                                        <div class="modal-body">
                                            <form onSubmit={handleSubmit}>

                                                <div class="">
                                                    <input type="text"
                                                        name="username"
                                                        value={formData.username}
                                                        onChange={handleChange}
                                                        required class="form-control" placeholder="Username" />
                                                </div>
                                                <div class="">
                                                    <input type="email"
                                                        name="email"
                                                        value={formData.email}
                                                        onChange={handleChange}
                                                        required class="form-control" placeholder="Enter your email" />
                                                </div>
                                               

                                                <div className="input-group">
                                                    <input
                                                        type={showOldPassword ? 'text' : 'password'}
                                                        className="form-control "
                                                        name='password'
                                                        placeholder="Password"
                                                        value={formData.password}
                                                        onChange={handleChange}
                                                        required
                                                        style={{ height: '50px' }} // Increase input height
                                                    />
                                                    <div className="input-group-append mt-2" style={{ position: 'absolute', right: '0rem', zIndex: '100' }}>
                                                        <button
                                                            type="button"
                                                            className="btn"
                                                            onClick={() => setShowOldPassword(!showOldPassword)}
                                                        >
                                                            <i className={showOldPassword ? 'bi bi-eye-slash' : 'bi bi-eye'}></i>
                                                        </button>
                                                    </div>
                                                </div>
                                                <div class="">
                                                    <input 
                                                    type={showOldPassword ? 'text' : 'password'}
                                                        name="confirmPassword"
                                                        value={formData.confirmPassword}
                                                        onChange={handleChange}
                                                        required class="form-control" placeholder="Confirm Password" />
                                                </div>
                                                <div class=" cs_check_box">
                                                    <input type="checkbox"
                                                        name="agreeToTerms"
                                                        checked={formData.agreeToTerms}
                                                        onChange={handleChange}
                                                        tabindex="0"
                                                        required id="check_box" class="common_checkbox" />
                                                    <label class="form-label" for="check_box">
                                                        I agree to terms and policy.
                                                    </label>
                                                </div>
                                                <button href="#" class="btn_1 full_width text-center" type='submit'> Sign Up</button>


                                                <p>Already Have an account? <Link to="/login">Log in</Link></p>
                                                

                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    );

};

export default Register;