import React, { useState } from "react";
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

const Copy = ({ text }) => {
    const [isCopied, setIsCopied] = useState(false);

    const handleCopy = async () => {
        try {
            await navigator.clipboard.writeText(text);
            setIsCopied(true);
            toast.success("Text copied to clipboard", {
                position: "top-right"
            });
            // Revert the icon back to its original state after 3 seconds
            setTimeout(() => {
                setIsCopied(false);
            }, 3000);
        } catch (err) {
            console.error('Failed to copy text', err);
        }
    }

    const handleButtonClick = () => {
        handleCopy();
    }

    return (
        <div onClick={handleButtonClick}>
            {isCopied ? (
                // Clipboard icon
                <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" height="24" width="24" xmlns="http://www.w3.org/2000/svg">
                    <path d="M17 5h4a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V7a2 2 0 0 1 2-2h4"></path>
                    <polyline points="9 10 4 15 9 20"></polyline>
                    <line x1="15" y1="15" x2="20" y2="10"></line>
                </svg>
            ) : (
                // Original icon
                <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" height="24" width="24" xmlns="http://www.w3.org/2000/svg">
                    <rect width="14" height="14" x="8" y="8" rx="2" ry="2"></rect>
                    <path d="M4 16c-1.1 0-2-.9-2-2V4c0-1.1.9-2 2-2h10c1.1 0 2 .9 2 2"></path>
                </svg>
            )}
        </div>
    );
}

export default Copy;
