import React from "react";
import { useState, useEffect } from "react";
import { Autoplay, FreeMode, Mousewheel, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import BaseLayout from "../components/layouts/BaseLayout";
import Container from "../components/Container";
import { MAIN_TABS } from "../consts";
import { Link } from "react-router-dom";
import SingleCollection from "./SingleCollection";
import { useLocation } from 'react-router-dom';
import ShowNft from "../../dashboard/components/shownft";
import { fetchEthToUsdRate } from "../../dashboard/api/ethrate";

const LayoutContainer = ({ Data }) => {
    // Destructure the artData for easier access
  const { name, item, chain, description, total_volume, listed, owners, unique_owners, bg_link, img } = Data;
  const UserData = {
    userid: "",
    category: name,
    offset: 0,
    limit: 20,
    page: true,
    mine: false,
};

    return (
        <BaseLayout>
            <div className="relative bg-white">

                <div
                    className="absolute inset-0 z-0 bg-cover bg-top bg-no-repeat transition-[background] duration-500 after:absolute after:inset-0 after:z-10 after:backdrop-blur-xl after:[background:linear-gradient(0deg,rgb(255,255,255)_5%,rgba(0,0,0,0)_60%)_rgba(0,0,0,0.5)]"
                    style={{ background:'white' }}
                />
                <div className="relative z-10 -mt-[4.25rem] pt-[4.25rem] bg-white">
                   
                    <Container className="px-0 sm:px-4 bg-white">
                    <Tabs />
                        <div class="white_box mb_30" style={{marginTop:'-100px',  margin:'0px', padding:'0px'}}>    
                            <div class="profile_card_5" >
                                <div class="cover-photo" style={{marginTop: '-150px', height:'250px', backgroundImage: `url(${bg_link})`}}>
                                    <img src={img} class="profile" style={{position: 'absolute', top: '105px', background: 'white'}} />
                                </div>
                                <div style={{margin:'0px', padding:'30px'}}>
                                <div class="profile-name" style={{margin:'0px', marginTop: '15px'}}>{name} <br />  </div>
                                <div class="flex gap-4"><p>Items <span class="font-bold">{item}</span></p><p>Chain <span class="font-bold">Ethereum</span></p></div>
                                <p class="about">{description}</p>
                                <div class="flex gap-4 md:gap-6 mt-2"><div class="flex flex-col"><span class="font-[900]">{total_volume}</span><span class="-mt-1">total volume</span></div><div class="flex flex-col"><span class="font-[900]">{listed}</span><span class="-mt-1">listed</span></div><div class="flex flex-col"><span class="font-[900]">{owners}</span><span class="-mt-1">owners</span></div><div class="flex flex-col"><span class="font-[900]">{unique_owners}</span><span class="-mt-1">unique owners</span></div></div>
                                
                                </div>
                            </div>
                        </div>
                    </Container>
                    <Container>
                        
                        <ShowNft userData={UserData} /> 
                        </Container>
                </div>
            </div>
        </BaseLayout>
    )
}
function Tabs() {
    // State to keep track of the active tab
    const [activeTab, setActiveTab] = useState(MAIN_TABS[0]); // Assuming MAIN_TABS is an array of tab names
    const location = useLocation();
    const pathname = location.pathname;
      // Split the pathname by '/'
      const segments = pathname.split('/');

      // Extract the segment you're interested in
      const route = segments[segments.length - 1];
      console.log(route)

    return (
        <div className="pt-8 sm:px-0">
            <nav className="scroll-mask" id="main-tabs">
                <Swiper spaceBetween={16} slidesPerView={'auto'} freeMode={true} navigation modules={[FreeMode, Navigation]} tag="ul">
                    {MAIN_TABS.map((tab) => (
                        <SwiperSlide key={tab} tag="li" className={`rounded-lg ${tab === route ? 'swiper-slide-active active first:bg-white/10 hover:bg-white/10' : ''}`}>
                            <Link 
                                to={tab === 'All' ? '../' : '/' + tab} 
                                className={`block w-fit px-4 py-2 text-sm font-semibold text-white md:text-base ${tab === activeTab ? 'text-blue-500' : 'text-white'}`}
                                onClick={() => setActiveTab(tab)} // Update active tab on click
                            >
                                {tab}
                            </Link>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </nav>
        </div>
    );
}
export default LayoutContainer;