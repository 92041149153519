import logo_dark from '../assets/logo.png';
import logo_light from '../assets/logo.png';
import { Bars3Icon, MagnifyingGlassIcon, ShoppingCartIcon, UserCircleIcon, LockClosedIcon } from '@heroicons/react/24/outline';
import { WalletIcon } from '@heroicons/react/24/solid';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import React, { useEffect, useRef, useState } from 'react';
import Container from './Container';
import { useAuth } from '../../dashboard/context/AuthContext';
import Swal from 'sweetalert2';

export default function Header() {
  // search function
  const { fetchAllnfts, allNfts, defUrllink } = useAuth();
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const searchTermRef = useRef(searchTerm); // useRef to keep track of searchTerm inside event listeners






  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value); 
    alert(searchTerm);
  };
  const handleSearch = (searchValue) => {
    // Simulating API call to fetch search results
    // Replace this with actual API call in your application
    try {
      // Example API call
      const response = fetchAllnfts();
      const searchResults = searchData(allNfts, searchValue);
      //const data = searchResults.json();
      // setSearchResults(data.results); // Assuming the API returns an array of search results
      if (searchValue) {
        const resultsHtml = searchResults.map(item => `
                      <a href="/product/${item.link}">
                      <div style="margin: 10px; display: flex; align-items: center; margin-bottom: 10px; border: 0px solid #ccc; border-radius: 10px; padding: 10px; background-color: rgb(245, 245, 245);">
                      <img src="${defUrllink + 'nftuploads/' + item.link}" alt="Icon" style="width: 40px;  height: 40px; margin-right: 20px; border-radius: 10%;">
                      <div style="flex-grow: 1;">
                        <div style="font-weight: bold; text-align: start;">${item.title}</div>
                        <div style="font-size: 0.8em; text-align: start;">${item.description}</div>
                      </div>
                    </div>
                    </a>
            `).join('');
        const swalResultsDiv = document.getElementById('swal-search-results');
        if (swalResultsDiv) {
          swalResultsDiv.innerHTML = resultsHtml;
          swalResultsDiv.style.maxHeight = '300px'; // Example height
          swalResultsDiv.style.overflowY = 'auto'; // Enable vertical scrollbar
          swalResultsDiv.style.marginTop = '10px'; // Add some spacing

        }
      } else {
        // Clear results if search term is cleared
        setSearchResults([]);
        const swalResultsDiv = document.getElementById('swal-search-results');
        if (swalResultsDiv) {
          swalResultsDiv.innerHTML = '';
        }
      }

    } catch (error) {
      console.error('Error fetching search results:', error);
    }
  };
  const searchData = (data, searchInput) => {
    const filteredData = data.filter(item => {
      if (item.title.toLowerCase().includes(searchInput.toLowerCase())) {
        return true;
      }
      return false;
    });
    return filteredData;
  };


  const handleSearchPopup = () => {
    Swal.fire({
      title: 'Search for Nfts',
      html: `<div class="form-group">
                <input type="text" id="swal-search-input" class="swal2-input" placeholder="Type to search...">
                <div id="swal-search-results" class="">No Results.</div>
            </div>`,
      showCloseButton: true,
      showCancelButton: false,
      showConfirmButton: false,
      position: 'top',
      buttons: [],
      customClass: {
        closeButton: 'swal-close-button',
        container: 'fullscreen-swal'
      },
      didOpen: () => {
        const searchInput = document.getElementById('swal-search-input');
        searchInput.focus();
        // Attach event listener directly to the input
        searchInput.addEventListener('input', (e) => {
          const value = e.target.value;
          setSearchTerm(value); // Update React state
          handleSearch(value); // Call a function to update Swal content based on the new search term
        });
      },
      willClose: () => {
        // Clean up: Remove the event listener to prevent memory leaks
        document.getElementById('swal-search-input').removeEventListener('input', handleSearchChange);
      }
    });
  };





  // other functions
  const ref = useRef(null);
  const [isSticked, setIsSticked] = useState(false);
  const Usericon = () => {
    const { currentUser } = useAuth();
    return currentUser ? <><Link to="/dashboard"><UserCircleIcon className="h-6 w-6 stroke-2" /></Link></> : <><Link to="/login"><LockClosedIcon className="h-6 w-6 stroke-2" /></Link></>;
  };

  useEffect(() => {
    const cachedRef = ref.current;
    const observer = new IntersectionObserver(([e]) => setIsSticked(e.intersectionRatio < 1), {
      threshold: [1],
    });
    if (cachedRef) observer.observe(cachedRef);
    return () => {
      if (cachedRef) observer.unobserve(cachedRef);
    };
    searchTermRef.current = searchTerm;





  }, [searchTerm]);

  return (
    <header
      className={clsx(
        'sticky -top-[0.1px] z-50 transition-colors duration-150',
        isSticked ? 'border-b bg-white text-black' : 'text-white'
      )}
      ref={ref}
    >
      <Container>
        <div className="flex h-[4.25rem] items-center gap-x-2 py-2.5">
          <button className="h-full px-1 xl:hidden hidden">
            <Bars3Icon className="h-8 w-8" />
          </button>
          <div className="flex h-full items-center">
            <div className="relative w-40">
              <Link to='/'>
              <img
                src={isSticked ? logo_dark : logo_light}
                loading="eager"
                className="absolute inset-0 my-auto object-contain"
                alt="logo"
              />
              </Link>
            </div>
            <ul
              className={clsx(
                'ml-6 hidden gap-x-6 border-l pl-6 font-semibold xl:flex',
                isSticked ? 'border-slate-200' : 'border-white/20'
              )}
            ></ul>
          </div>
          <div className="relative ml-6 hidden h-full flex-1 sm:block md:hidden">

            <MagnifyingGlassIcon
              className={clsx('absolute inset-y-0 left-3 z-10 my-auto h-5 w-5 stroke-2', isSticked && 'text-slate-500')}
            />
            <input
              name="Search"
              type="text"
              className={clsx(
                'h-full w-full rounded-xl border bg-white/10 pl-10 hover:bg-white/20 focus:ring-0',
                isSticked
                  ? 'border-slate-200 focus:border-slate-200'
                  : 'border-transparent placeholder:text-slate-200 focus:border-transparent'
              )}
              placeholder="Search items, collections, and accounts"
            />
          </div>
          <div className="hidden h-full lg:flex">
            <button
              className={clsx(
                'flex h-full items-center rounded-l-xl border-y border-l bg-white/10 px-4 font-semibold hover:bg-white/20',
                !isSticked && 'border-transparent'
              )}
            >
              <WalletIcon className="mr-2 h-6 w-6 stroke-2" />
              Connect wallet
            </button>
            <button
              className={clsx(
                'h-full rounded-r-xl border bg-white/10 px-3 hover:bg-white/20',
                !isSticked && 'border-transparent border-l-white/20'
              )}
            >
              <UserCircleIcon className="h-6 w-6 stroke-2" />
            </button>
          </div>
          <button onClick={handleSearchPopup}
            className={clsx(
              'ml-auto h-full rounded-xl border bg-white/10 px-3 hover:bg-white/20 ',
              !isSticked && 'border-transparent'
            )}
          >
            <MagnifyingGlassIcon className="h-6 w-6 stroke-2" />
          </button>
          <button
            className={clsx('h-full rounded-xl border bg-white/10 px-3 hover:bg-white/20', !isSticked && 'border-transparent')}
          >

            <Usericon />

          </button>
        </div>
      </Container>
    </header>
  );
}
